import React from 'react';
import { useTranslation } from 'react-i18next';
import { observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
import { Grid, Typography, FormControlLabel, Checkbox, makeStyles, createStyles } from '@material-ui/core';
import { UserRole } from '@mtt-nails/consts';
import { Item, Form } from '../helper';
import { IFormItem } from '../interface';
import { colors } from '../../../themes/theme';

interface RoleType {
  key: number;
  label: string;
  value: string;
  checked: boolean;
}

const initial: RoleType[] = [
  { key: 0, label: 'Owner', value: UserRole.SHOP_OWNER, checked: false },
  { key: 1, label: 'Share Holder', value: UserRole.SHARE_HOLDER, checked: false },
  { key: 2, label: 'Nailer', value: UserRole.NAILER, checked: false },
  { key: 4, label: 'User', value: UserRole.USER, checked: false },
  { key: 5, label: 'Manager', value: UserRole.MANAGER, checked: false },
  { key: 6, label: 'Tax', value: UserRole.TAX, checked: false },
];

export class Role implements IFormItem<string[]> {
  @observable
  form: Form = new Form();

  @observable
  roles: RoleType[];

  constructor(roles: RoleType[] = initial) {
    this.roles = roles;
    this.roles.map(role => {
      return (this.form.items[role.value] = new Item({ value: role.checked }));
    });
  }

  getRoles = () => {
    const roles: string[] = [];
    this.roles.map(role => {
      if (this.form.items[role.value].value) {
        roles.push(role.value);
      }
      return null;
    });
    return roles;
  };

  @computed
  get value() {
    return this.getRoles();
  }

  setValue = () => {};

  isDirty = () => this.form.isDirty();

  isValid = () => this.form.isValid();

  @action
  onReset = () => {
    this.form.onReset();
  };

  @action
  onSetDefault = () => {
    this.form.onSetDefault();
  };
}

interface Props {
  data: Role;
  disabled?: boolean;
}

const UserRoleControl: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();
  const classes = styles();

  return (
    <Grid item xs={12}>
      <fieldset className={classes.field}>
        <legend>
          <Typography>{t('Roles')}</Typography>
        </legend>
        {data.roles.map(role => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.form.items[role.value].value}
                  key={role.key}
                  onChange={() => data.form.items[role.value].setValue(!data.form.items[role.value].value)}
                  color="secondary"
                />
              }
              label={t(role.label)}
              key={role.key}
            />
          );
        })}
      </fieldset>
    </Grid>
  );
};

const styles = makeStyles(() =>
  createStyles({
    field: {
      borderColor: colors.white_6,
      borderRadius: 5,
      margin: '10px 0',
    },
  }),
);

export default observer(UserRoleControl);
