import React, { Component } from 'react';
import { IconButton } from '@material-ui/core';
import { Edit, RemoveCircle, CheckCircle } from '@material-ui/icons';
import { ActionType } from '../Store';

interface Props {
  name: string;
  onClick?: () => void;
}

export default class ActionIcon extends Component<Props> {
  render() {
    const { name, onClick } = this.props;
    return (
      <IconButton aria-label={name} onClick={onClick}>
        {name === ActionType.EDIT && <Edit />}
        {name === ActionType.DELETE && <RemoveCircle />}
        {name === ActionType.VERIFY && <CheckCircle />}
      </IconButton>
    );
  }
}
