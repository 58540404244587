import React, { useEffect } from 'react';
import { InputAdornment, Grid, createStyles, makeStyles, Theme } from '@material-ui/core';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import { observer, Observer } from 'mobx-react';
import { CountryCode } from 'libphonenumber-js';
import Input from './Input';
import { IFormItem } from '../interface';
import { colors } from '../../../themes/theme';

export interface SelectCountryBoxProps {
  disabled?: boolean;
  data: IFormItem<string>;
}

const SelectCountryBox: React.FC<SelectCountryBoxProps> = (props: SelectCountryBoxProps) => {
  const { data, ...others } = props;
  const flagsSelectRef: React.RefObject<any> = React.useRef();

  const handleChangeCountryCode = (countryCode: string) => {
    props.data.setValue(countryCode as CountryCode);
  };

  const classes = styles();
  const { t } = useTranslation();

  useEffect(() => {
    flagsSelectRef.current.updateSelected(data.value as CountryCode);
  }, [data.value]);

  return (
    <Grid item>
      <Input
        label={t('Country')}
        data={data}
        className={classes.addressInput}
        disabled={others.disabled}
        InputProps={{
          inputProps: {
            className: classes.input,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Observer>
                {() => (
                  <ReactFlagsSelect
                    searchable
                    blackList
                    showSelectedLabel={false}
                    disabled={others.disabled}
                    onSelect={handleChangeCountryCode}
                    defaultCountry={data.value}
                    ref={flagsSelectRef}
                    countries={['AS', 'AG']}
                    className={!data.value ? classes.inputNoFlag : classes.flagBox}
                  />
                )}
              </Observer>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginLeft: -50,
    },
    inputNoFlag: {
      marginRight: theme.spacing(1),
      width: 200,
      outline: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      caretColor: colors.transparent,
    },
    flagBox: {
      width: 110,
    },
    addressInput: {
      width: 195,
      outline: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      caretColor: colors.transparent,
    },
  }),
);
export default observer(SelectCountryBox);
