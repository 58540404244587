import { BaseStore } from '../../../cores';
import { action, observable, runInAction } from 'mobx';
import { supportApi, Support } from '@mtt-nails/apis/dist/v3';
import { ModalStore } from '../../../components/Modal';
import i18n from '../../../i18n';
import { ShopAction } from './constant';
import { ShopStatus } from '@mtt-nails/consts';
class ListStore extends BaseStore {
  @observable
  modalStore: ModalStore = new ModalStore();

  @observable
  row: Support.ShopBase;

  @observable
  action: string;

  @action
  closeModal = () => {
    this.modalStore.isModalOpen = false;
  };

  @action
  submitForm = async () => {
    try {
      if (this.action === ShopAction.REMOVE) {
        await supportApi.removeShop(this.row.id);
        runInAction(() => {
          this.row.status = ShopStatus.Removed;
        });
      } else if (this.action === ShopAction.VERIFY) {
        await supportApi.verifyShop(this.row.id);
        runInAction(() => {
          this.row.status = ShopStatus.Verified;
        });
      }
      this.setSuccess(i18n.t('Success'));
    } catch (err) {
      this.setError(err);
    } finally {
      runInAction(() => {
        this.modalStore.isModalOpen = false;
      });
    }
  };

  @action
  shopProcess = async (row: Support.ShopBase, action: ShopAction) => {
    this.action = action;
    this.row = row;
    this.modalStore.isModalOpen = true;
    this.modalStore.modalMessage = i18n.t(`Do you really want to ${action} shop?`);
  };
}

export default ListStore;
