enum AppMap {
  Dashboard = '/',
  Login = '/login',
  Profile = '/profile',
  ShopManagement = '/shop-management',
  ShopCreating = '/shop-management/new',
  ShopEditing = '/shop-management/edit/:shopId',
  UserManagement = '/user-management',
  UserCreating = '/user-management/new',
  UserEditing = '/user-management/edit/:userId',
  StaffManagement = '/staff-management',
  StaffEditing = '/staff-management/edit/:id',
}

export default AppMap;
