import React from 'react';
import { CountryCode } from 'libphonenumber-js';

import {
  DefaultForm,
  AddressForm,
  PhoneForm,
  BonusForm,
  SalaryForm,
  OffDateForm,
  DateForm,
  SwitchForm,
  TimeForm,
  ContactForm,
  NameForm,
  PermissionForm,
} from './Group';

import { InputType } from './type';

interface Control {
  item: any;
  editable: boolean;
  cca2: CountryCode;
  setCca2: (code: string) => void;
  dialingCode: string;
}

function createControl({ item, editable, setCca2, dialingCode, cca2 }: Control) {
  switch (item.inputType as InputType) {
    case 'default':
      return <DefaultForm key={item.label} store={item} editable={editable} type={item.type} />;
    case 'address':
      return <AddressForm key={item.label} store={item} editable={editable} setCca2={setCca2} />;
    case 'phone':
      return <PhoneForm key={item.label} store={item} editable={editable} dialingCode={dialingCode} />;
    case 'bonus':
      return <BonusForm key={item.label} store={item} editable={editable} />;
    case 'salary':
      return <SalaryForm key={item.label} store={item} editable={editable} />;
    case 'offDate':
      return <OffDateForm key={item.label} store={item} editable={editable} />;
    case 'birthday':
      return <DateForm key={item.label} store={item} editable={editable} />;
    case 'switch':
      return <SwitchForm key={item.label} store={item} editable={editable} />;
    case 'time':
      return <TimeForm key={item.label} store={item} editable={editable} cca2={cca2} />;
    case 'contact':
      return <ContactForm key={item.label} store={item} editable={editable} dialingCode={dialingCode} />;
    case 'name':
      return <NameForm key={item.label} store={item} editable={editable} />;
    case 'permission':
      return <PermissionForm key={item.label} store={item} editable={editable} />;
    default:
      return null;
  }
}

export { createControl };
