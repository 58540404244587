import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  return <Grid container>{t(`Hello`)}</Grid>;
};

export default Dashboard;
