import React from 'react';
import { observable, runInAction, action, computed, toJS } from 'mobx';
import { AxiosPromise } from 'axios';
import { BaseStore } from '../../cores';

export enum ActionType {
  EDIT = 'edit',
  DELETE = 'delete',
  VERIFY = 'verify',
}

export enum SortType {
  DESC = 'desc',
  ASC = 'asc',
}

interface Sortable<T> {
  order: SortType;
  orderBy: string;
}

interface Filterable<T> {
  items: { value: string; label: string }[];
  selectedValue: string;
  filter: (item: T) => boolean;
}

interface Paging {
  page: number;
  rowsPerPage: number;
}

export interface Action<T> {
  name: string;
  onClick: (row: T) => void;
}

interface ActionsCol<T> {
  label: string;
  actions: Action<T>[];
}

export interface TableConfig<T> {
  displayName?: string;
  leftTopTable?: React.ReactNode;
  rightTopTable?: React.ReactNode;
  items: T[];
  columns: IColumnData<T>[];
  filter?: Filterable<T>;
  paging?: Paging;
  actionsCol?: ActionsCol<T>;
}

export interface IColumnData<T> {
  key: string;
  header: string;
  valueGetter: (row: T, items?: T[]) => string;
  sort?: (val1: T, val2: T, order: SortType) => number;
}

export default class DataTable<T> extends BaseStore {
  displayName?: string;

  leftTopTable?: React.ReactNode;
  rightTopTable?: React.ReactNode;

  @observable items: T[];

  @observable columns: IColumnData<T>[] = [];

  @observable filterable: Filterable<T>;

  @observable paging: Paging = { page: 0, rowsPerPage: 5 };

  @observable sort: Sortable<T>;

  actionsCol: ActionsCol<T>;

  constructor(init: TableConfig<T>) {
    super();
    this.displayName = init.displayName;
    this.leftTopTable = init.leftTopTable;
    this.rightTopTable = init.rightTopTable;
    this.items = init.items;
    this.columns = init.columns;
    this.sort = {
      order: SortType.ASC,
      orderBy: this.columns[0].key,
    };
    if (init.actionsCol) this.actionsCol = init.actionsCol;
  }

  @computed
  get filteredItems() {
    const { page, rowsPerPage } = this.paging;
    const filterd =
      !this.filterable || !this.filterable.selectedValue
        ? this.items
        : this.items.filter(item => this.filterable.filter(item));
    const paging = filterd.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const sorted = paging.sort(this.sortHandler);
    return sorted;
  }

  sortHandler = (ele1: T, ele2: T) => {
    const column = this.columns.find(column => column.key === this.sort.orderBy);
    if (!column || !column.sort) return 0;

    return column.sort(ele1, ele2, this.sort.order);
  };

  @action
  setFilterable = (filterable: Filterable<T>) => (this.filterable = filterable);

  @action
  setFilterSelectedValue = (id: string) => (this.filterable.selectedValue = id);

  @action
  setPage = (page: number) => (this.paging.page = page);

  @action
  setRowsPerPage = (rowsPerPage: number) => (this.paging.rowsPerPage = rowsPerPage);

  @action
  setSort = (value: string, type: SortType) => {
    this.sort.orderBy = value;
    this.sort.order = type;
  };

  @action
  setItems = (items: T[]) => {
    this.items = toJS(items);
  };

  fetchRemote = async (fetcher: () => AxiosPromise<T[]>) => {
    try {
      const { data } = await fetcher();
      runInAction(() => (this.items = data));
    } catch (error) {
      this.setError(error);
    }
  };
}
