import React from 'react';
import { Typography, Breadcrumbs as Breadcrumb, makeStyles, createStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { NavigateNext } from '@material-ui/icons';
import { colors } from '../../themes/theme';

interface Item {
  name: string;
  url?: string;
}

interface Props {
  items: Item[];
}

const Breadcrumbs = (props: Props) => {
  const classes = styles();
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb
        className={classes.breadcrumbs}
        separator={<NavigateNext fontSize="small" />}
        arial-label="Breadcrumb">
        {props.items.map((item, index) => {
          if (index === props.items.length - 1) {
            return (
              <Typography key={item.name} className={classes.linkEnd}>
                {t(item.name)}
              </Typography>
            );
          }
          return (
            <Link key={item.name} className={classes.link} to={item.url as string}>
              {t(item.name)}
            </Link>
          );
        })}
      </Breadcrumb>
    </>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      fontSize: theme.spacing(3),
      color: theme.palette.common.black,
    },
    linkEnd: {
      textDecoration: 'none',
      fontSize: theme.spacing(3),
      color: colors.purple,
    },
    breadcrumbs: {
      fontSize: theme.spacing(3),
    },
  }),
);

export default observer(Breadcrumbs);
