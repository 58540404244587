import React from 'react';
import { observer, Observer, useLocalStore } from 'mobx-react';
import { Grid, Button, Typography, makeStyles, Theme, createStyles } from '@material-ui/core';
import AppMap from '../../AppMap';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Store from './Store';
import { Support } from '@mtt-nails/apis/dist/v3';
import { runInAction, reaction } from 'mobx';
import DataTable, { ActionType } from '../../../components/Table_pagination/Store';
import userTableConfig from './tableConfig';
import { Table_pagination as Table, Alert } from '../../../components';
import { colors } from '../../../themes/theme';

interface Props extends RouteComponentProps {}
const store = new Store();

const List: React.FC<Props> = (props: Props) => {
  const classes = styles();
  const { t } = useTranslation();

  //TODO - Update table row with other ways
  reaction(
    () => store.isUpdateUI,
    async value => {
      if (value) {
        dataTable.getData();
        runInAction(() => {
          store.isUpdateUI = false;
        });
      }
    },
  );

  const dataTable: DataTable<Support.Profile> = useLocalStore(
    () =>
      new DataTable({
        ...userTableConfig,
        actionsCol: {
          label: t('Actions'),
          actions: [
            {
              name: ActionType.EDIT,
              onClick: (row: Support.Profile) => props.history.push(`${AppMap.UserManagement}/edit/${row.id}`),
            },
            {
              name: ActionType.DELETE,
              onClick: async (row: Support.Profile) => {
                await store.removeUser(row.id);
              },
            },
          ],
        },
      }),
  );

  const handleCreate = () => {
    props.history.push(AppMap.UserCreating);
  };

  return (
    <>
      <Grid>
        <Table data={dataTable} />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={handleCreate} className={classes.button}>
          <Typography className={classes.text}>{t('Create')}</Typography>
        </Button>
      </Grid>
      <Observer>{() => <Alert store={store.alertStore} onClose={store.hideAlert} />}</Observer>
    </>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: 5,
      marginRight: theme.spacing(1),
    },
    text: {
      color: colors.white,
    },
  }),
);

export default observer(List);
