import React from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { SelectDate } from '../Items';
import { Form } from '../type';

function OffDateForm({ store, editable }: Form<Date[]>) {
  const { t } = useTranslation();

  const handleChange = (value: Date[]) => {
    const [date] = value;
    const offDates = [...store.value];
    const idx = offDates.findIndex(d => moment(d).isSame(moment(date)));

    if (idx >= 0) offDates.splice(idx, 1);
    else offDates.push(date);

    store.setValue([...offDates]);
  };

  return (
    <Box>
      <SelectDate editable={editable} value={store.value} setValue={handleChange} label={t('Off date')} />
    </Box>
  );
}

export default observer(OffDateForm);
