import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { StoreNames, RootStore } from '../../../stores';
import { Breadcrumbs, Alert, Tabs } from '../../../components';
import Preloader from '../../../containers/Preloader';
import AppMap from '../../AppMap';
import Store from './store';
import WorkInformation from './WorkInformation';
import PersonalInformation from './PersonalInformation';

interface Props extends RootStore {}

const useStyles = makeStyles(() => ({
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    height: '100%',
  },
}));

const Detail = (props: Props) => {
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation();

  const store = useMemo(() => new Store(props.Shops.shops), [props.Shops.shops]);

  useEffect(() => {
    store.getUser(id);
  }, [id, store]);

  const options = [
    { label: t('Personal Information'), component: <PersonalInformation store={store} /> },
    { label: t('Work Information'), component: <WorkInformation store={store} /> },
  ];

  return (
    <Preloader loading={store.loading}>
      {!store.user.name ? (
        <Box className={classes.textContainer}>
          <Typography variant="h3">{t('Account not exists')}</Typography>
        </Box>
      ) : (
        <Box>
          <Breadcrumbs items={[{ name: 'List', url: AppMap.StaffManagement }, { name: store.user.name }]} />
          <Box mt={2}>
            <Tabs options={options} />
          </Box>
        </Box>
      )}
      <Alert store={store.alertStore} onClose={store.hideAlert} />
    </Preloader>
  );
};

export default inject(StoreNames.Shops)(observer(Detail));
