import { Support } from '@mtt-nails/apis/dist/v3';

import { TableConfig, SortType } from '../../../components/Table/Store';
import { utils } from '../../../cores';
import i18n from '../../../i18n';

const tableConfig: TableConfig<Support.UserBase> = {
  displayName: 'Shop Users',
  items: [],
  columns: [
    {
      key: 'name',
      header: 'Name',
      valueGetter: (row: Support.UserBase) => row.name,
      sort: (val1: Support.UserBase, val2: Support.UserBase, order: SortType) =>
        utils.Sortable.sortByString(val1.name, val2.name, order),
    },
    {
      key: 'shopName',
      header: 'Shop',
      valueGetter: (row: Support.UserBase) => row.shopName || '',
      sort: (val1: Support.UserBase, val2: Support.UserBase, order: SortType) =>
        utils.Sortable.sortByString(val1.shopName || '', val2.shopName || '', order),
    },
    {
      key: 'staffCode',
      header: 'Staff Code',
      valueGetter: (row: Support.UserBase) => row.staffCode,
      sort: (val1: Support.UserBase, val2: Support.UserBase, order: SortType) =>
        utils.Sortable.sortByString(val1.staffCode, val2.staffCode, order),
    },
    {
      key: 'relation',
      header: 'Relation',
      valueGetter: (row: Support.UserBase) => i18n.t(row.relation),
      sort: (val1: Support.UserBase, val2: Support.UserBase, order: SortType) =>
        utils.Sortable.sortByString(val1.relation, val2.relation, order),
    },
    {
      key: 'status',
      header: 'Status',
      valueGetter: (row: Support.UserBase) => i18n.t(row.status),
      sort: (val1: Support.UserBase, val2: Support.UserBase, order: SortType) =>
        utils.Sortable.sortByString(val1.status, val2.status, order),
    },
  ],
};

export default tableConfig;
