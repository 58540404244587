import { observable, action, computed } from 'mobx';
import { cloneDeep } from 'lodash';
import { Support } from '@mtt-nails/apis/dist/v3';

import { InputType, FormItem, InputValue, TName } from './type';

export class Form {
  inputType: InputType;
  key: string;
  @observable label: string;
  @observable value: InputValue;
  @observable defaultValue: InputValue;
  @observable validation?: string;

  constructor({ label, key, validation }: any) {
    this.label = label;
    this.key = key;
    this.validation = validation;
  }

  @computed
  get isDirty() {
    if (typeof this.value !== typeof this.defaultValue) return true;
    if (typeof this.value !== 'object') return this.value !== this.defaultValue;
    return JSON.stringify(this.value) !== JSON.stringify(this.defaultValue);
  }

  @action setValue = (value: InputValue) => (this.value = value);
  @action setDefaultValue = (value: InputValue) => (this.defaultValue = value);
  @action resetValue = () => (this.value = cloneDeep(this.defaultValue));
  // Used when update success
  @action updateValue = () => (this.defaultValue = cloneDeep(this.value));
}

export class DefaultForm extends Form {
  inputType: InputType = 'default';
  type?: string;
  constructor({ label, value, validation, key, type }: FormItem<string | number>) {
    super({ label, key, validation });
    this.value = value;
    this.defaultValue = value;
    this.type = type;
  }
}
export class AddressForm extends Form {
  inputType: InputType = 'address';

  @observable value: Support.Address;
  @observable defaultValue: Support.Address;

  constructor({ label, value, validation, key }: FormItem<Support.Address>) {
    super({ label, key, validation });
    this.value = Support.Address.from(value);
    this.defaultValue = Support.Address.from(value);
  }
}
export class PhoneForm extends Form {
  inputType: InputType = 'phone';
  @observable value: Support.Phone;
  @observable defaultValue: Support.Phone;

  constructor({ label, value, validation, key }: FormItem<Support.Phone>) {
    super({ label, key, validation });
    this.value = Support.Phone.from(value);
    this.defaultValue = Support.Phone.from(value);
  }
}

export class BonusForm extends Form {
  inputType: InputType = 'bonus';
  @observable value: Support.StaffBonus;
  @observable defaultValue: Support.StaffBonus;

  constructor({ label, value, validation, key }: FormItem<Support.StaffBonus>) {
    super({ label, key, validation });
    this.value = Support.StaffBonus.from(value);
    this.defaultValue = Support.StaffBonus.from(value);
  }
}

export class SalaryForm extends Form {
  inputType: InputType = 'salary';
  @observable value: Support.StaffSalary;
  @observable defaultValue: Support.StaffSalary;

  constructor({ label, value, validation, key }: FormItem<Support.StaffSalary>) {
    super({ label, key, validation });
    this.value = { ...value };
    this.defaultValue = { ...value };
  }
}

export class OffDateForm extends Form {
  inputType: InputType = 'offDate';
  @observable value: Date[];
  @observable defaultValue: Date[];

  constructor({ label, value, key, validation }: FormItem<Date[]>) {
    super({ label, key, validation });
    this.value = value;
    this.defaultValue = value;
  }
}

export class DateForm extends Form {
  inputType: InputType = 'birthday';
  @observable value: Date | null;
  @observable defaultValue: Date | null;

  constructor({ label, value, key, validation }: FormItem<Date | null>) {
    super({ label, key, validation });
    this.value = value;
    this.defaultValue = value;
  }
}
export class SwitchForm extends Form {
  inputType: InputType = 'switch';
  @observable value: boolean;
  @observable defaultValue: boolean;

  constructor({ label, value, key, validation }: FormItem<boolean>) {
    super({ label, key, validation });
    this.value = value;
    this.defaultValue = value;
  }
}
export class TimeForm extends Form {
  inputType: InputType = 'time';
  @observable value: Support.OpeningTimes;
  @observable defaultValue: Support.OpeningTimes;

  constructor({ label, value, key, validation }: FormItem<Support.OpeningTimes>) {
    super({ label, key, validation });
    this.value = Support.OpeningTimes.from(value);
    this.defaultValue = Support.OpeningTimes.from(value);
  }
}
export class ContactForm extends Form {
  inputType: InputType = 'contact';
  @observable value: Support.Contact;
  @observable defaultValue: Support.Contact;

  constructor({ label, value, key, validation }: FormItem<Support.Contact>) {
    super({ label, key, validation });
    this.value = Support.Contact.from(value);
    this.defaultValue = Support.Contact.from(value);
  }
}
export class NameForm extends Form {
  inputType: InputType = 'name';
  @observable value: TName;
  @observable defaultValue: TName;

  constructor({ label, value, key, validation }: FormItem<TName>) {
    super({ label, key, validation });
    this.value = value;
    this.defaultValue = value;
  }
}
export class PermissionForm extends Form {
  inputType: InputType = 'permission';
  @observable value: string[];
  @observable defaultValue: string[];

  constructor({ label, value, key, validation }: FormItem<string[]>) {
    super({ label, key, validation });
    this.value = value;
    this.defaultValue = value;
  }
}
