import { RouteProps } from 'react-router-dom';

import AppMap from './AppMap';
import Login from './Login';
import Dashboard from './Dashboard';
import Profile from './Profile';
import * as ShopManagement from './ShopManagement';
import * as UserManagement from './UserManagement';
import * as StaffManagement from './StaffManagement';

const privateRoutes: RouteProps[] = [
  { path: AppMap.Dashboard, component: Dashboard, exact: true },
  { path: AppMap.ShopManagement, component: ShopManagement.List, exact: true },
  { path: AppMap.ShopCreating, component: ShopManagement.Detail, exact: true },
  { path: AppMap.ShopEditing, component: ShopManagement.Detail, exact: true },
  { path: AppMap.UserManagement, component: UserManagement.List, exact: true },
  { path: AppMap.UserCreating, component: UserManagement.Signup, exact: true },
  { path: AppMap.UserEditing, component: UserManagement.Edit, exact: true },
  { path: AppMap.Profile, component: Profile, exact: true },
  { path: AppMap.StaffManagement, component: StaffManagement.List, exact: true },
  { path: AppMap.StaffEditing, component: StaffManagement.Detail, exact: true },
];

const publishRoutes: RouteProps[] = [{ path: AppMap.Login, component: Login }];

export { privateRoutes, publishRoutes };
