import AddressControl from './Groups/AddressControl';
import ContactControl from './Groups/ContactControl';
import DateTimeControl from './Groups/DateTimeControl';
import NameControl from './Groups/NameControl';
import PasswordControl from './Groups/PasswordControl';
import ChangePasswordControl from './Groups/ChangePasswordControl';
import RoleControl from './Groups/RoleControl';

import Input from './Items/Input';
import PasswordInput from './Items/PasswordInput';
import PhoneInput from './Items/PhoneInput';
import SelectBox from './Items/SelectBox';
import SelectCountryBox from './Items/SelectCountryBox';
import SelectDayOffBox from './Items/SelectDayOffBox';
import Time from './Items/Time';

const Groups = {
  AddressControl,
  ContactControl,
  DateTimeControl,
  NameControl,
  PasswordControl,
  ChangePasswordControl,
  RoleControl,
};

const Items = {
  Input,
  PasswordInput,
  PhoneInput,
  SelectBox,
  SelectCountryBox,
  SelectDayOffBox,
  Time,
};

const FormComponents = {
  Groups,
  Items,
};

export default FormComponents;
