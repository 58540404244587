import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Observer } from 'mobx-react';
import { Support } from '@mtt-nails/apis/dist/v3';
import { useTranslation } from 'react-i18next';

import { PhoneInput } from '../Items';
import { Form } from '../type';

interface Props extends Form<Support.Phone> {
  dialingCode: string;
}

function PhoneForm({ store, editable, dialingCode }: Props) {
  const { t } = useTranslation();
  const handleChange = (key: string) => (value: string) => {
    store.setValue({
      ...store.value,
      [key]: value,
    });
  };
  useEffect(() => {
    dialingCode !== store.value.dialing_code &&
      store.setValue({
        ...store.value,
        dialing_code: dialingCode,
      });
  }, [dialingCode, store]);
  return (
    <Grid container>
      <Grid item>
        <Observer>
          {() => (
            <PhoneInput
              value={store.value.number}
              dialingCode={store.value.dialing_code}
              label={t('Phone number')}
              setValue={handleChange('number')}
              editable={editable}
            />
          )}
        </Observer>
      </Grid>
    </Grid>
  );
}

export default PhoneForm;
