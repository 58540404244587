import { utils } from '../../../cores';
import { TableConfig, SortType } from '../../../components/Table/Store';
import { Support } from '@mtt-nails/apis/dist/v3';
import i18n from '../../../i18n';

const shopTableConfig: TableConfig<Support.ShopBase> = {
  displayName: 'Shop list',
  items: [],
  columns: [
    {
      key: 'name',
      header: 'Shop name',
      valueGetter: (row: Support.ShopBase) => row.name,
      sort: (val1: Support.ShopBase, val2: Support.ShopBase, order: SortType) =>
        utils.Sortable.sortByString(val1.name, val2.name, order),
    },
    {
      key: 'address',
      header: 'Address',
      valueGetter: (row: Support.ShopBase) => `${row.address.street} ${row.address.city} ${row.address.country}`,
      sort: (val1: Support.ShopBase, val2: Support.ShopBase, order: SortType) =>
        utils.Sortable.sortByString(
          `${val1.address.street} ${val1.address.city} ${val1.address.country}`,
          `${val2.address.street} ${val2.address.city} ${val2.address.country}`,
          order,
        ),
    },
    {
      key: 'openingTimes',
      header: 'Openning times',
      valueGetter: (row: Support.ShopBase) =>
        `${row.openingTimes && row.openingTimes.open} - ${row.openingTimes && row.openingTimes.close}`,
      sort: (val1: Support.ShopBase, val2: Support.ShopBase, order: SortType) =>
        utils.Sortable.sortByString(
          `${val1.openingTimes && val1.openingTimes.open} - ${val1.openingTimes && val1.openingTimes.close}`,
          `${val2.openingTimes && val2.openingTimes.open} - ${val2.openingTimes && val2.openingTimes.close}`,
          order,
        ),
    },
    {
      key: 'email',
      header: 'E-mail',
      valueGetter: (row: Support.ShopBase) => row.contact.email,
      sort: (val1: Support.ShopBase, val2: Support.ShopBase, order: SortType) =>
        utils.Sortable.sortByString(val1.contact.email, val2.contact.email, order),
    },
    {
      key: 'contact',
      header: 'Phone number',
      valueGetter: (row: Support.ShopBase) => `${row.contact.phone.dialing_code} ${row.contact.phone.number}`,
      sort: (val1: Support.ShopBase, val2: Support.ShopBase, order: SortType) =>
        utils.Sortable.sortByString(
          `${val1.contact.phone.dialing_code} ${val1.contact.phone.number}`,
          `${val2.contact.phone.dialing_code} ${val2.contact.phone.number}`,
          order,
        ),
    },
    {
      key: 'status',
      header: 'Status',
      valueGetter: (row: Support.ShopBase) => {
        console.log({ trans: i18n.t(row.status) });
        return i18n.t(row.status);
      },
      sort: (val1: Support.ShopBase, val2: Support.ShopBase, order: SortType) =>
        utils.Sortable.sortByString(`${val1.status}`, `${val2.status}`, order),
    },
  ],
};

export default shopTableConfig;
