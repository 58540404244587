import React from 'react';
import { Snackbar, makeStyles, createStyles, SnackbarContent, IconButton } from '@material-ui/core';
import { TVariant } from './types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { useTranslation } from 'react-i18next';
import { green, amber, red } from '@material-ui/core/colors';
import { Close } from '@material-ui/icons';

export * from './types';

export class AlertStore {
  @observable
  isOpen: boolean;

  @observable
  message: string;

  @observable
  variant: TVariant;
}

interface Props {
  store: AlertStore;
  onClose(): void;
}

const Alert: React.FC<Props> = (props: Props) => {
  const { store, onClose } = props;
  const { t } = useTranslation();
  const classes = styles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={store.isOpen}
      autoHideDuration={5000}
      onClose={onClose}>
      <SnackbarContent
        message={<span className={classes.message}>{t(`${store.message}`)}</span>}
        className={classes[store.variant as keyof typeof classes]}
        action={
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <Close />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

const styles = makeStyles(() =>
  createStyles({
    success: {
      backgroundColor: green[600],
    },
    warning: {
      backgroundColor: amber[700],
    },
    danger: {
      backgroundColor: red[600],
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export default observer(Alert);
