/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { observable, action, toJS } from 'mobx';
import { AlertStore, TVariant } from '../components/Alert';
import { isObject, get } from 'lodash';

const errorFormApi = (error: any) => {
  return (
    isObject(error) &&
    error.hasOwnProperty('config') &&
    error.hasOwnProperty('request') &&
    error.hasOwnProperty('response')
  );
};

export const getMessage = (data: any) => {
  let message = '';
  if (typeof data === 'string') {
    message = data;
  } else if (errorFormApi(data)) {
    message = get(data, 'response.data.message');
  } else if (isObject(data) && data.hasOwnProperty('message')) {
    message = get(data, 'message');
  }

  return message;
};

class BaseStore {
  @observable alertStore = new AlertStore();

  @action hideAlert = () => (this.alertStore.isOpen = false);
  @action showAlert = ({ message, variant }: { message: string; variant: TVariant }) => {
    this.alertStore = {
      ...toJS(this.alertStore),
      message,
      variant,
      isOpen: true,
    };
  };

  @action
  setSuccess = (message: string) => {
    this.showAlert({ message, variant: 'success' });
  };
  @action
  setError = (_error: any) => {
    const message = getMessage(_error);
    this.showAlert({ message, variant: 'danger' });
  };
  debug = (_error: any) => {};
}

export default BaseStore;
