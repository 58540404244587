/* eslint-disable no-console */
import { BaseStore } from '../cores';
import { observable, action } from 'mobx';
import { Support } from '@mtt-nails/apis/dist/v3';
import { IAsyncTracking } from './AsyncTracking';

interface IAuthorization {
  isAuthenticated: boolean;
  authorize: (authorization: Support.Authorization) => void;
  deauthorize: () => void;
}

const Storage = {
  save: async (auth: Support.Authorization) => {
    return localStorage.setItem('authorization', JSON.stringify(auth));
  },

  load: async () => {
    try {
      const authString = await localStorage.getItem('authorization');
      if (authString !== null) {
        const auth: Support.Authorization = JSON.parse(authString);
        return auth;
      }
    } catch (err) {
      console.debug(err);
    }
  },

  clear: async () => localStorage.removeItem('authorization'),
};

class AuthorizationStore extends BaseStore implements IAuthorization, IAsyncTracking {
  interceptor: number | undefined;
  authorization: Support.Authorization | undefined;
  @observable isAuthenticated = false;

  load = () => this.loadAuthFromStorage();

  loadAuthFromStorage = async () => {
    try {
      const auth = await Storage.load();
      if (auth) this.authorize(auth);
    } catch (err) {
      this.debug(err);
    }
  };

  @action
  authorize = (authorization: Support.Authorization) => {
    this.isAuthenticated = true;
    this.authorization = authorization;
    Storage.save(this.authorization);
  };

  @action
  deauthorize = () => {
    this.isAuthenticated = false;
    this.authorization = undefined;
    Storage.clear();
  };
}

export default AuthorizationStore;
