import * as React from 'react';
import Progress from './Progress';

interface Props {
  loading?: boolean;
}

class Preloader extends React.PureComponent<Props> {
  render() {
    const { loading = true, children } = this.props;
    if (loading) {
      return <Progress />;
    }
    return children;
  }
}

export default Preloader;
