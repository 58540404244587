import i18n from 'i18next';
import en from './locales/en.json';
import de from './locales/de.json';
import vi from './locales/vi.json';

i18n.init({
  keySeparator: '>',
  nsSeparator: '|',
  lng: 'en',
  resources: {
    en: {
      common: en,
    },
    de: {
      common: de,
    },
    vi: {
      common: vi,
    },
  },
  ns: ['common'],
  defaultNS: 'common',
});

export default i18n;
