import { configure, observable, action, runInAction, reaction } from 'mobx';
import AuthorizationStore from './AuthorizationStore';
import LanguageStore from './LanguageStore';
import UserStore from './UserStore';
import ShopStore from './ShopStore';
import { BaseStore } from '../cores';
import socket from '@mtt-nails/socket-events';
import { supportApi } from '@mtt-nails/apis/dist/v3';

configure({ enforceActions: 'observed' });

export enum StoreNames {
  Authorization = 'Authorization',
  Language = 'Language',
  User = 'User',
  Shops = 'Shops',
}

export interface RootStore {
  [StoreNames.Authorization]: AuthorizationStore;
  [StoreNames.Language]: LanguageStore;
  [StoreNames.User]: UserStore;
  [StoreNames.Shops]: ShopStore;
}

class Store extends BaseStore {
  interceptor: number;

  @observable isReady = false;

  rootStore: RootStore;
  constructor() {
    super();
    this.rootStore = {
      [StoreNames.Authorization]: new AuthorizationStore(),
      [StoreNames.Language]: new LanguageStore(),
      [StoreNames.User]: new UserStore(),
      [StoreNames.Shops]: new ShopStore(),
    };

    this.registerEvents();
  }

  registerEvents() {
    reaction(
      () => this.rootStore[StoreNames.Authorization].isAuthenticated,
      (isAuthenticated: boolean) => {
        if (isAuthenticated) {
          this.inject();
          this.rootStore[StoreNames.User].load();
          this.rootStore[StoreNames.Shops].load();
        } else {
          this.eject();
        }
      },
    );

    reaction(
      () => this.rootStore[StoreNames.User].profile.id,
      id => {
        const { authorization } = this.rootStore[StoreNames.Authorization];
        if (id && authorization) {
          socket.connect(authorization.access_token);
        } else {
          socket.leave();
        }
      },
    );
  }

  @action
  loadDeps = async () => {
    try {
      await Promise.all([this.rootStore[StoreNames.Authorization].load(), this.rootStore[StoreNames.Language].load()]);
    } catch (error) {
      this.debug(error);
    } finally {
      runInAction(() => (this.isReady = true));
    }
  };

  inject() {
    const { authorization, deauthorize } = this.rootStore[StoreNames.Authorization];
    if (!authorization) return;
    supportApi.authorizeApi(authorization);

    this.interceptor = supportApi.inject.response(error => {
      if (error && error.response && error.response.status === 401) deauthorize();
    });
  }

  eject() {
    if (this.interceptor !== undefined) supportApi.eject.response(this.interceptor);
    // authorizeApi();
  }
}

export const createRootStore = (): Store => new Store();
