import { observable, computed, action, runInAction } from 'mobx';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';
import i18n from '../../../i18n';
import { BaseStore } from '../../../cores';
import { ModalStore } from '../../../components/Modal';
import { Form } from '../../../components/FormComponents/helper';
import { Address } from '../../../components/FormComponents/Groups/AddressControl';
import { Contact } from '../../../components/FormComponents/Groups/ContactControl';
import { Name } from '../../../components/FormComponents/Groups/NameControl';
import { Password } from '../../../components/FormComponents/Groups/PasswordControl';
import { Role } from '../../../components/FormComponents/Groups/RoleControl';

class UserStore extends BaseStore {
  @observable
  modalStore: ModalStore = new ModalStore();

  @observable
  form: Form;

  @observable
  alreadyEdit = () => this.form.isDirty();

  @computed
  get isEditable() {
    return this.validate() && this.alreadyEdit();
  }

  constructor(data: Support.Profile = Support.Profile.from({})) {
    super();
    this.form = new Form();
    this.form.items['name'] = new Name({ firstName: data.firstName, lastName: data.lastName });
    this.form.items['address'] = new Address(data.address);
    this.form.items['contact'] = new Contact({ phone: data.phone, email: data.email });
    this.form.items['password'] = new Password();
    this.form.items['roles'] = new Role();
  }

  validate(): boolean {
    return this.form.isValid();
  }

  @action
  reset = () => {
    this.form.onReset();
    this.alreadyEdit = () => this.form.isDirty();
  };

  @action
  submitFormCreateAccount = async () => {
    try {
      const data = {
        firstName: this.form.items.name.value.firstName,
        lastName: this.form.items.name.value.lastName,
        address: this.form.items.address.value,
        email: this.form.items.contact.value.email,
        phone: this.form.items.contact.value.phone,
        roles: this.form.items.roles.value,
        password: this.form.items.password.value.password,
      };
      await supportApi.createUserBySupporter(data);
      runInAction(() => {
        this.reset();
        this.modalStore.isModalOpen = false;
        this.setSuccess(i18n.t('Success'));
      });
    } catch (err) {
      runInAction(() => {
        this.modalStore.isModalOpen = false;
        this.setError(err);
      });
    }
  };

  @action
  onCancel = () => {
    this.form.onReset();
  };

  @action
  onCreateUser = () => {
    this.modalStore.isModalOpen = true;
    this.modalStore.modalTitle = i18n.t('Create Account');
    this.modalStore.modalMessage = i18n.t('Are you sure to create account ?');
  };

  @action
  closeModal = () => {
    this.modalStore.isModalOpen = false;
  };
}

export default UserStore;
