/* eslint-disable no-use-before-define */
import React from 'react';
import bg from './bg.svg';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    background: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      [theme.breakpoints.down('xs')]: {
        width: 400,
      },
      [theme.breakpoints.up('sm')]: {
        width: 600,
      },
    },
  });

interface Props extends WithStyles<typeof styles> {}

const Background = (props: Props) => {
  return <img src={bg} alt="" className={props.classes.background} />;
};

export default withStyles(styles)(Background);
