import React from 'react';
import { observer } from 'mobx-react';
import { Grid, makeStyles, Theme, createStyles, TextField, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReportPanel } from '../../../../components';

interface Language {
  language: string;
  code: string;
}

interface Props {
  appSupportedLanguages: Language[];
  locale: string;
  setLocale: any;
}

const UserSetting: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const classes = styles();
  const { appSupportedLanguages, locale } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setLocale(event.target.value);
  };

  return (
    <Grid className={classes.margin}>
      <ReportPanel title={t('Personal Setting')}>
        <TextField
          select
          label={t('Select')}
          value={locale}
          onChange={handleChange}
          helperText={t('Setting default language')}
          margin="normal">
          {appSupportedLanguages.map((language, index) => (
            <MenuItem key={index} value={language.code}>
              {language.language}
            </MenuItem>
          ))}
        </TextField>
      </ReportPanel>
    </Grid>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    container: {
      paddingTop: 15,
    },
  }),
);

export default observer(UserSetting);
