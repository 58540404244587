import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { DefaultInput } from '../Items';
import { Form, TName } from '../type';

interface Props extends Form<TName> {}

function NameForm({ store, editable }: Props) {
  const { t } = useTranslation();

  const changeValue = (key: string) => (value: string) => {
    store.setValue({
      ...store.value,
      [key]: value,
    });
  };

  return (
    <Grid container>
      <Grid item>
        <DefaultInput
          label={t('First name')}
          value={store.value.firstName}
          editable={editable}
          setValue={changeValue('firstName')}
        />
      </Grid>
      <Grid item>
        <DefaultInput
          label={t('Last name')}
          value={store.value.lastName}
          editable={editable}
          setValue={changeValue('lastName')}
        />
      </Grid>
    </Grid>
  );
}

export default observer(NameForm);
