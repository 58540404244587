import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Permissions } from '@mtt-nails/consts';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxInput } from '../Items';
import { Form } from '../type';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(),
  },
}));

type TPermission = keyof typeof Permissions;

const PermissionForm: React.FC<Form<string[]>> = ({ store, editable }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const setValue = (permission: string) => (check: boolean) => {
    const value = [...store.value];
    if (check) {
      store.setValue([...value, permission]);
    } else {
      const idx = value.findIndex(val => val === permission);
      value.splice(idx, 1);
      store.setValue(value);
    }
  };

  return (
    <Grid container className={classes.root}>
      {Object.keys(Permissions).map(key => {
        return (
          <CheckboxInput
            key={key}
            editable={editable}
            value={store.value.includes(Permissions[key as TPermission])}
            setValue={setValue(Permissions[key as TPermission])}
            label={t(key)}
          />
        );
      })}
    </Grid>
  );
};

export default observer(PermissionForm);
