import React from 'react';
import { Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PopoverCalendar from '../../PopoverCalendar';
import { FormInput } from '../type';

const useStyles = makeStyles(() => ({
  btn: {
    width: 195,
  },
}));

function SelectDate({ value, setValue, label, editable }: FormInput<Date[]>) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDayClick = (day: Date) => {
    setValue([day]);
  };
  const open = Boolean(anchorEl);

  return (
    <Box m={1}>
      <Button variant="outlined" color="secondary" onClick={handleClick} disabled={!editable} className={classes.btn}>
        {label}
      </Button>
      <PopoverCalendar
        onDayClick={handleDayClick}
        onDelete={handleDayClick}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        selectedDays={value}
      />
    </Box>
  );
}

export default SelectDate;
