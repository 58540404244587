import React from 'react';
import { Grid } from '@material-ui/core';
import { Observer, observer } from 'mobx-react';
import { Support } from '@mtt-nails/apis/dist/v3';
import { useTranslation } from 'react-i18next';

import { DefaultInput, DateInput } from '../Items';
import { Form } from '../type';

const BonusForm: React.FC<Form<Support.StaffBonus>> = ({ editable, store }) => {
  const { t } = useTranslation();

  const onChange = (key: string) => (value: any) => {
    store.setValue({
      ...store.value,
      [key]: value,
    });
  };

  return (
    <Grid container>
      <Grid item>
        <Observer>
          {() => (
            <DefaultInput
              editable={editable}
              value={String(store.value.amount)}
              label={t('Bonus')}
              setValue={onChange('amount')}
              type="number"
            />
          )}
        </Observer>
      </Grid>
      <Grid item>
        <Observer>
          {() => (
            <DateInput
              editable={editable}
              value={store.value.startDate}
              label={t('Start date')}
              setValue={onChange('startDate')}
            />
          )}
        </Observer>
      </Grid>
      <Grid item>
        <Observer>
          {() => (
            <DateInput
              editable={editable}
              value={store.value.endDate}
              label={t('End date')}
              setValue={onChange('endDate')}
              minDate={store.value.startDate}
            />
          )}
        </Observer>
      </Grid>
    </Grid>
  );
};

export default observer(BonusForm);
