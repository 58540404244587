import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FormInput } from '../type';

interface Props extends FormInput<Date | null> {
  minDate?: Date | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    margin: theme.spacing(),
  },
}));

const DateInput: React.FC<Props> = ({ value, setValue, editable, label, minDate }) => {
  const classes = useStyles();

  return (
    <DatePicker
      className={classes.input}
      value={value}
      label={label}
      onChange={setValue}
      disabled={!editable}
      inputVariant="outlined"
      format={'MM/dd/yyyy'}
      placeholder="MM/dd/yyyy"
      minDate={minDate}
    />
  );
};

export default DateInput;
