export { default as AddressForm } from './AddressForm';
export { default as DefaultForm } from './DefaultForm';
export { default as PhoneForm } from './PhoneForm';
export { default as BonusForm } from './BonusForm';
export { default as SalaryForm } from './SalaryForm';
export { default as OffDateForm } from './OffDateForm';
export { default as DateForm } from './DateForm';
export { default as SwitchForm } from './SwitchForm';
export { default as TimeForm } from './TimeForm';
export { default as ContactForm } from './ContactForm';
export { default as NameForm } from './NameForm';
export { default as PermissionForm } from './PermissionForm';
