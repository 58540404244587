import { Support, supportApi } from '@mtt-nails/apis/dist/v3';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { action, autorun, computed, observable, reaction } from 'mobx';

import { AddressForm, ContactForm, Form, NameForm, PermissionForm } from '../../../components/FormControl/Store';
import { TName } from '../../../components/FormControl/type';
import { BaseStore } from '../../../cores';

interface IModal {
  isModalOpen: boolean;
  modalTitle: string;
  modalMessage: string;
}

export default class extends BaseStore {
  @observable profile: Support.Profile = Support.Profile.from({});
  @observable items: Form[] = [];
  @observable countryCode: CountryCode = 'DE';
  @observable dialingCode: string;
  @observable editable = true;
  @observable modal: IModal = {
    isModalOpen: false,
    modalTitle: '',
    modalMessage: '',
  };

  constructor(profile: Support.Profile) {
    super();
    this.profile = profile;
    this.init(profile);
  }

  changeProfile = autorun(() => {
    this.init(this.profile);
  });

  @computed
  get isDirty() {
    return this.items.some(item => item.isDirty);
  }
  @computed
  get userId() {
    return this.profile.id;
  }

  @action setModal = (modal: Partial<IModal>) => {
    this.modal = { ...this.modal, ...modal };
  };
  @action setProfile = (profile: Support.Profile) => (this.profile = profile);
  @action setEditable = (editable: boolean) => (this.editable = editable);
  @action setCca2 = (code: string) => (this.countryCode = code as CountryCode);
  @action reset = () => this.items.forEach(item => item.resetValue());
  @action update = () => this.items.forEach(item => item.updateValue());
  @action changeDialingCode = reaction(
    () => this.countryCode,
    code => {
      const dialingCode = getCountryCallingCode(code);
      this.dialingCode = `+${dialingCode}`;
    },
  );
  @action init = ({ address, email, phone, firstName, lastName, permissions }: Support.Profile) => {
    this.items = [];
    this.items.push(new NameForm({ label: 'name', key: 'name', value: { lastName, firstName } }));
    this.items.push(new AddressForm({ label: 'Address', key: 'address', value: address }));
    if (address.country) {
      const code = address.country as CountryCode;
      const dialingCode = `+${getCountryCallingCode(code)}`;
      this.dialingCode = dialingCode;
      const _phone: Support.Phone = {
        ...phone,
        dialing_code: dialingCode,
      };
      this.items.push(new ContactForm({ label: 'Address', key: 'contact', value: { email, phone: _phone } }));
    } else {
      this.items.push(new ContactForm({ label: 'Address', key: 'contact', value: { email, phone } }));
    }
    this.items.push(new PermissionForm({ label: 'permissions', key: 'permissions', value: permissions }));
  };
  @action getItem = (key: string) => {
    const item = this.items.find(e => e.key === key);
    return item || { value: {} };
  };

  save = async () => {
    const { firstName, lastName } = this.getItem('name').value as TName;
    const { phone, email } = Support.Contact.from(this.getItem('contact').value);
    const address = Support.Address.from(this.getItem('address').value);
    const permissions = this.getItem('permissions').value as string[];

    const data = { firstName, lastName, phone, email, address, permissions };
    try {
      this.setModal({ isModalOpen: false });
      await supportApi.updateProfileById(this.userId, data);
      this.update();
    } catch (error) {
      this.setError(error);
    }
  };
}
