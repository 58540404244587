import React from 'react';
import { useTranslation } from 'react-i18next';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { InputAdornment } from '@material-ui/core';
import { IFormItem } from '../interface';
import Input from './Input';

interface Props {
  dialing_code: string;
  data: IFormItem<string>;
  InputProps?: Partial<OutlinedInputProps>;
}

const PhoneInput: React.FC<Props> = (props: Props) => {
  const { data, ...others } = props;
  const { t } = useTranslation();
  return (
    <Input
      label={t('Phone number')}
      data={data}
      InputProps={{
        startAdornment: <InputAdornment position="start">{props.dialing_code}</InputAdornment>,
      }}
      {...others}
    />
  );
};

export default PhoneInput;
