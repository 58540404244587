import React, { Component } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { StoreNames, RootStore } from '../stores';
import AppMap from '../screens/AppMap';
import PrivateLayout from './PrivateLayout';

interface Props extends RouteProps, RootStore {}

@observer
export class PrivateRoute extends Component<Props> {
  render() {
    if (!this.props[StoreNames.Authorization].isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: AppMap.Login,
            state: { from: this.props.location },
          }}
        />
      );
    }
    const { component: ChildComponent, ...others } = this.props;
    if (ChildComponent)
      return (
        <Route
          {...others}
          render={props => (
            <PrivateLayout>
              <ChildComponent {...props} />
            </PrivateLayout>
          )}
        />
      );
  }
}

export default PrivateRoute;
