import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';
import { Observer, observer, useLocalStore } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { Alert, Modal, Table } from '../../../components';
import DataTable, { ActionType } from '../../../components/Table/Store';
import { colors } from '../../../themes/theme';
import AppMap from '../../AppMap';
import { ShopAction } from './constant';
import Store from './Store';
import shopTableConfig from './tableConfigShopInfo';

interface Props extends RouteComponentProps {}
const store = new Store();

const List: React.FC<Props> = (props: Props) => {
  const classes = styles();
  const { t } = useTranslation();

  const showDetail = (row: Support.ShopBase) => {
    props.history.push(`${AppMap.ShopManagement}/edit/${row.id}`);
  };

  const dataTable: DataTable<Support.ShopBase> = useLocalStore(
    () =>
      new DataTable({
        ...shopTableConfig,
        actionsCol: {
          label: t('Actions'),
          actions: [
            {
              name: ActionType.EDIT,
              onClick: showDetail,
            },
            {
              name: ActionType.DELETE,
              onClick: async (row: Support.ShopBase) => {
                store.shopProcess(row, ShopAction.REMOVE);
              },
            },
            {
              name: ActionType.VERIFY,
              onClick: async (row: Support.ShopBase) => {
                store.shopProcess(row, ShopAction.VERIFY);
              },
            },
          ],
        },
      }),
  );

  useEffect(() => {
    dataTable.fetchRemote(supportApi.getShops);
  }, [dataTable]);

  const handleCreate = () => {
    props.history.push(AppMap.ShopCreating);
  };

  return (
    <>
      <Grid>
        <Table data={dataTable} onDoubleClickRow={showDetail} />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={handleCreate} className={classes.button}>
          <Typography className={classes.text}>{t('Create')}</Typography>
        </Button>
      </Grid>
      <Modal store={store.modalStore} onClose={store.closeModal} onSubmit={store.submitForm} />
      <Observer>{() => <Alert store={store.alertStore} onClose={store.hideAlert} />}</Observer>
    </>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: 5,
      marginRight: theme.spacing(1),
    },
    text: {
      color: colors.white,
    },
  }),
);

export default observer(List);
