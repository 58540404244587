/* eslint-disable no-use-before-define */
import * as React from 'react';
import { withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from './styles';
import { WithStyles } from '@material-ui/core';

interface Props extends WithStyles<typeof styles> {
  loading?: boolean;
}

class Progress extends React.PureComponent<Props> {
  render() {
    const { loading = true, children, classes } = this.props;
    return (
      <React.Fragment>
        {loading && (
          <div className={classes.container}>
            <div className={classes.linear}>
              <LinearProgress />
            </div>
          </div>
        )}
        {children}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Progress);
