import { observable, runInAction, action, reaction, computed } from 'mobx';
import { supportApi, Support } from '@mtt-nails/apis/dist/v3';
import { getCountryCallingCode, CountryCode } from 'libphonenumber-js';
import { StaffStatus } from '@mtt-nails/consts';

import BaseStore from '../../../cores/BaseStore';
import i18n from '../../../i18n';
import {
  AddressForm,
  PhoneForm,
  DefaultForm,
  Form,
  BonusForm,
  SalaryForm,
  OffDateForm,
  DateForm,
  SwitchForm,
} from '../../../components/FormControl/Store';
import { InputValue } from '../../../components/FormControl/type';

export default class extends BaseStore {
  @observable loading = false;
  @observable items: Form[] = [];
  @observable itemWorks: Form[] = [];
  @observable user: Support.User = Support.User.from({});
  @observable countryCode: CountryCode = 'DE';
  @observable dialingCode: string;
  @observable shops: Support.ShopBase[] = [];

  @computed
  get isDirty() {
    return this.items.some(item => item.isDirty);
  }
  @computed
  get isWorkInformationDirty() {
    return this.itemWorks.some(item => item.isDirty);
  }
  @computed
  get userStatus() {
    return this.user.status;
  }
  @computed
  get shopName() {
    const shop = this.shops.find(shop => shop.id === this.user.shopId);
    return shop ? shop.name : '';
  }

  constructor(shops: Support.ShopBase[]) {
    super();
    this.shops = shops;
  }

  @action
  changeDialingCode = reaction(
    () => this.countryCode,
    code => {
      const dialingCode = getCountryCallingCode(code);
      this.dialingCode = `+${dialingCode}`;
    },
  );

  @action init = ({
    name,
    address,
    phone,
    nickName,
    timeBlocking,
    expectedWorkingHoursPerMonth,
    table,
    staffCode,
    bonus,
    salary,
    offDates,
    dateOfBirth,
    isHidden,
  }: Support.User) => {
    this.items = [];
    this.itemWorks = [];

    this.items.push(new DefaultForm({ key: 'name', label: 'Name', value: name }));
    this.items.push(new DefaultForm({ key: 'nickName', label: 'Nickname', value: nickName }));
    this.items.push(new AddressForm({ key: 'address', label: 'Address', value: address }));
    this.items.push(new PhoneForm({ key: 'phone', label: 'Phone', value: phone }));
    this.items.push(new DateForm({ key: 'dateOfBirth', label: 'Birthday', value: dateOfBirth }));

    this.itemWorks.push(new DefaultForm({ key: 'staffCode', label: 'Staff Code', value: staffCode }));
    this.itemWorks.push(
      new DefaultForm({ type: 'number', key: 'timeBlocking', label: 'Time Blocking', value: timeBlocking }),
    );
    this.itemWorks.push(new DefaultForm({ type: 'number', key: 'table', label: 'Table', value: table }));
    this.itemWorks.push(
      new DefaultForm({
        type: 'number',
        key: 'expectedWorkingHoursPerMonth',
        label: 'Working Hours Per Month',
        value: expectedWorkingHoursPerMonth,
      }),
    );
    this.itemWorks.push(new BonusForm({ key: 'bonus', label: 'Bonus', value: bonus }));
    this.itemWorks.push(new SalaryForm({ key: 'salary', label: 'Salary', value: salary }));
    this.itemWorks.push(new OffDateForm({ key: 'offDates', label: 'Off Date', value: offDates }));
    this.itemWorks.push(new SwitchForm({ key: 'isHidden', label: 'Hidden', value: isHidden }));

    if (address.country) {
      const code = address.country as CountryCode;
      this.countryCode = code;
      const dialingCode = getCountryCallingCode(code);
      const idx = this.items.findIndex(i => i.key === 'phone');
      if (idx) {
        const phone = this.items[idx];
        phone.setValue({ ...(phone.value as Support.Phone), dialing_code: `+${dialingCode}` });
        phone.setDefaultValue({ ...(phone.value as Support.Phone), dialing_code: `+${dialingCode}` });
      }
    }
  };
  @action setCca2 = (code: string) => (this.countryCode = code as CountryCode);
  @action resetItem = () => this.items.forEach(item => item.resetValue());
  @action resetWorkItem = () => this.itemWorks.forEach(item => item.resetValue());

  getUser = async (id: string) => {
    runInAction(() => {
      this.loading = true;
    });
    try {
      const { data } = await supportApi.getShopUserDetail(id);
      runInAction(() => {
        this.user = data;
        this.init(data);
      });
    } catch (error) {
      this.setError(error);
      runInAction(() => {
        this.user = Support.User.from({});
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  updateStatus = async () => {
    const status = {
      [StaffStatus.Active]: StaffStatus.Deactive,
      [StaffStatus.Pending]: StaffStatus.Active,
      [StaffStatus.Delete]: StaffStatus.Active,
      [StaffStatus.Deactive]: StaffStatus.Active,
    };
    try {
      await supportApi.updateShopUser({ id: this.user.id, status: status[this.userStatus] });
      runInAction(() => {
        this.user.status = status[this.userStatus];
      });
      this.setSuccess(i18n.t('Success'));
    } catch (error) {
      this.setError(error);
    }
  };
  handleUpdate = async (items: Form[]) => {
    const data: { [key: string]: InputValue } = {
      id: this.user.id,
    };
    items.forEach(item => {
      if (item.isDirty) {
        data[item.key] = item.value;
      }
    });
    try {
      await supportApi.updateShopUser(data);
      items.forEach(item => {
        if (item.isDirty) {
          item.updateValue();
        }
      });
      this.setSuccess(i18n.t('Success'));
    } catch (error) {
      this.setError(error);
    }
  };
}
