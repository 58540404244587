import React from 'react';
import { Grid } from '@material-ui/core';
import { observer, Observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import { Support } from '@mtt-nails/apis/dist/v3';
import { IFormItem } from '../interface';
import { Item, OffDatesList, Form, SpecialWorkDay } from '../helper';
import Time from '../Items/Time';
import SelectDayOffBox from '../Items/SelectDayOffBox';
import SpecialDate from '../Items/SpecialDay';

interface OpeningTimesType {
  open: string;
  close: string;
  timezone: string;
  offDates: string[];
  specialWorkDay: Support.SpecialWorkDay[];
}

const initial: OpeningTimesType = {
  open: '',
  close: '',
  timezone: '',
  offDates: [],
  specialWorkDay: [],
};
export class OpeningTimes implements IFormItem<OpeningTimesType> {
  @observable
  form: Form = new Form();

  constructor(openingTimes: OpeningTimesType = initial) {
    this.form.items['open'] = new Item({ value: openingTimes.open });
    this.form.items['close'] = new Item({ value: openingTimes.close });
    this.form.items['timezone'] = new Item({ value: openingTimes.timezone });
    this.form.items['offDates'] = new OffDatesList({ value: openingTimes.offDates });
    this.form.items['specialWorkDay'] = new SpecialWorkDay({ value: openingTimes.specialWorkDay });
  }

  @computed
  get value() {
    return {
      open: this.form.items['open'].value,
      close: this.form.items['close'].value,
      timezone: this.form.items['timezone'].value,
      offDates: [...this.form.items['offDates'].value],
      specialWorkDay: [...this.form.items['specialWorkDay'].value],
    };
  }

  setValue = () => {};

  isDirty = () => this.form.isDirty();

  isValid = () => this.form.isValid();

  @action
  onReset = () => {
    this.form.onReset();
  };

  @action
  onSetDefault = () => {
    this.form.onSetDefault();
  };

  @action
  setTimezone = (timezone: string) => (this.form.items.timezone.value = timezone);
}

interface Props {
  data?: OpeningTimes;
  disabled?: boolean;
}

const ShopDateTimeControl: React.FC<Props> = (props: Props) => {
  const { data = new OpeningTimes(), disabled } = props;

  return (
    <Grid item container xs={12} spacing={4}>
      <Observer>{() => <Time data={data.form.items.open} disabled={disabled} />}</Observer>
      <Observer>{() => <Time data={data.form.items.close} disabled={disabled} />}</Observer>
      <Observer>{() => <SelectDayOffBox data={data.form.items.offDates as OffDatesList} />}</Observer>
      <Observer>
        {() => (
          <SpecialDate
            disabled={!!disabled}
            selectedDate={Support.SpecialWorkDay.fromArray(data.form.items.specialWorkDay.value)}
            onAddSpecialDay={data.form.items.specialWorkDay.setValue}
            onDeleteSpecialDay={data.form.items.specialWorkDay.setValue}
          />
        )}
      </Observer>
    </Grid>
  );
};

export default observer(ShopDateTimeControl);
