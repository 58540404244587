import { Grid } from '@material-ui/core';
import { Support } from '@mtt-nails/apis/dist/v3';
import { Observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultInput, CountryInput } from '../Items';
import { Form } from '../type';

interface Props extends Form<Support.Address> {
  setCca2: (code: string) => void;
}

function Item({
  value,
  label,
  onChange,
  editable,
}: {
  value: string;
  label: string;
  editable: boolean;
  onChange: (value: string) => void;
}) {
  return (
    <Grid item>
      <DefaultInput value={value} label={label} setValue={onChange} editable={editable} />
    </Grid>
  );
}

function AddressForm({ store, editable, setCca2 }: Props) {
  const { t } = useTranslation();
  const handleChange = (key: string) => (value: string) => {
    if (key === 'country') setCca2(value);
    store.setValue({
      ...store.value,
      [key]: value,
    });
  };

  return (
    <Grid container>
      <Observer>
        {() => (
          <Grid item>
            <CountryInput
              editable={editable}
              label={t('Country')}
              value={store.value.country}
              setValue={handleChange('country')}
            />
          </Grid>
        )}
      </Observer>
      <Observer>
        {() => <Item value={store.value.city} label={t('City')} onChange={handleChange('city')} editable={editable} />}
      </Observer>
      <Observer>
        {() => (
          <Item value={store.value.street} label={t('Street')} onChange={handleChange('street')} editable={editable} />
        )}
      </Observer>
      <Observer>
        {() => <Item value={store.value.zip} label={t('Zip')} onChange={handleChange('zip')} editable={editable} />}
      </Observer>
      <Observer>
        {() => (
          <Item value={store.value.state} label={t('State')} onChange={handleChange('state')} editable={editable} />
        )}
      </Observer>
    </Grid>
  );
}

export default AddressForm;
