import { DateTimePicker } from '@material-ui/pickers';
import React from 'react';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

interface IProps {
  disabled?: boolean;
  label?: string;
  date: Date | null;
  minDate?: Date;
  onChange?: (date: Date | null) => void;
}

const DateTime: React.FC<IProps> = ({ disabled, label, minDate, date, onChange = noop }) => {
  const { t } = useTranslation();

  return (
    <DateTimePicker
      label={label}
      value={date}
      onChange={onChange}
      inputVariant="outlined"
      disabled={disabled}
      format={'MM/dd/yyyy HH:mm'}
      placeholder="MM/DD/YYYY HH:mm"
      minutesStep={15}
      minDate={minDate}
      okLabel={t('OK')}
      cancelLabel={t('Cancel')}
    />
  );
};

export default DateTime;
