import React from 'react';
import { observer, inject, useLocalStore } from 'mobx-react';
import { RootStore, StoreNames } from '../../stores';
import { UserInformation, UserSetting, ChangePassword } from './components';
import Store from './Store';
import { Alert, Modal } from '../../components';

interface Props extends RootStore {}

const Profile: React.FC<Props> = (props: Props) => {
  const store: Store = useLocalStore(() => new Store());
  const { appSupportedLanguages, locale, setLocale } = props[StoreNames.Language];
  return (
    <>
      <UserInformation store={store} />
      <ChangePassword store={store} />
      <UserSetting appSupportedLanguages={appSupportedLanguages} locale={locale} setLocale={setLocale} />
      <Modal store={store.modalStore} onClose={store.closeModal} onSubmit={store.submitForm} />
      <Alert store={store.alertStore} onClose={store.hideAlert} />
    </>
  );
};

export default inject(StoreNames.User, StoreNames.Language)(observer(Profile));
