import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, Observer } from 'mobx-react';
import { observable, computed, action } from 'mobx';
import { Grid } from '@material-ui/core';
import { Item, Form } from '../helper';
import { IFormItem } from '../interface';
import PasswordInput from '../Items/PasswordInput';

interface PasswordType {
  password: string;
  retypePassword: string;
}

const initial: PasswordType = {
  password: '',
  retypePassword: '',
};

export class Password implements IFormItem<PasswordType> {
  @observable
  form: Form = new Form();

  constructor(password: PasswordType = initial) {
    this.form.items['password'] = new Item({ value: password.password });
    this.form.items['retypePassword'] = new Item({ value: password.retypePassword });
  }

  @computed
  get value() {
    return {
      password: this.form.items['password'].value,
      retypePassword: this.form.items['retypePassword'].value,
    };
  }

  setValue = () => {};

  isDirty = () => this.form.isDirty();

  isValid = () => this.form.isValid();

  @action
  onReset = () => {
    this.form.onReset();
  };

  @action
  onSetDefault = () => {
    this.form.onSetDefault();
  };
}

interface Props {
  data: Password;
  disabled?: boolean;
}

const PasswordControl: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} spacing={4}>
      <Observer>{() => <PasswordInput label={t('Password')} data={data.form.items.password} />}</Observer>
      <Observer>{() => <PasswordInput label={t('Re-type password')} data={data.form.items.retypePassword} />}</Observer>
    </Grid>
  );
};

export default observer(PasswordControl);
