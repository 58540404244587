import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, Observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import { Grid } from '@material-ui/core';
import { Item, Form } from '../helper';
import { IFormItem } from '../interface';
import Input from '../Items/Input';
import PhoneInput from '../Items/PhoneInput';

interface ContactType {
  email: string;
  phone: { number: string; dialing_code: string };
}

const initial: ContactType = {
  email: '',
  phone: {
    dialing_code: '',
    number: '',
  },
};

export class Contact implements IFormItem<ContactType> {
  @observable
  form: Form = new Form();

  constructor(contact: ContactType = initial) {
    this.form.items['email'] = new Item({ value: contact.email });
    this.form.items['number'] = new Item({ value: contact.phone.number });
    this.form.items['dialing_code'] = new Item({
      value: contact.phone.dialing_code,
    });
  }

  @computed
  get value() {
    return {
      email: this.form.items['email'].value,
      phone: {
        number: this.form.items['number'].value,
        dialing_code: this.form.items['dialing_code'].value,
      },
    };
  }

  setValue = () => {};

  isDirty = () => this.form.isDirty();

  isValid = () => this.form.isValid();

  @action
  onReset = () => {
    this.form.onReset();
  };

  @action
  onSetDefault = () => {
    this.form.onSetDefault();
  };

  @action
  setDialingCode = (dialing_code: string) => (this.form.items['dialing_code'].value = dialing_code);
}

interface Props {
  data: Contact;
}

const UserContactControl: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} spacing={4}>
      <Observer>
        {() => <PhoneInput data={data.form.items.number} dialing_code={data.form.items.dialing_code.value} />}
      </Observer>

      <Observer>{() => <Input label={t('Email')} data={data.form.items.email} />}</Observer>
    </Grid>
  );
};

export default observer(UserContactControl);
