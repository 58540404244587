import React, { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Tab, Box, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  options: {
    label: string;
    component: React.ReactNode;
  }[];
  withPaper?: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    tabPanel: {
      padding: 0,
      marginTop: theme.spacing(2),
    },
    container: ({ withPaper }: any) => (withPaper ? { padding: theme.spacing(2) } : {}),
  };
});

const Tabs: React.FC<Props> = ({ options, withPaper }) => {
  const classes = useStyles({ withPaper });
  const [value, setValue] = useState('0');

  const handleChange = (e: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const Container = withPaper ? Paper : Box;

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange}>
        {options.map((option, idx) => (
          <Tab key={option.label} label={option.label} value={`${idx}`} />
        ))}
      </TabList>
      {options.map((option, idx) => (
        <TabPanel className={classes.tabPanel} key={option.label} value={`${idx}`}>
          <Container className={classes.container}>{option.component}</Container>
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default Tabs;
