import { Grid } from '@material-ui/core';
import { Support } from '@mtt-nails/apis/dist/v3';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultInput, PhoneInput } from '../Items';
import { Form } from '../type';

interface Props extends Form<Support.Contact> {
  dialingCode: string;
}

const ContactForm: React.FC<Props> = ({ editable, store, dialingCode }) => {
  const { t } = useTranslation();
  const changeEmail = (email: string) => {
    store.setValue({
      ...store.value,
      email,
    });
  };
  const changePhoneNumber = (phoneNumber: string) => {
    store.setValue({
      ...store.value,
      phone: {
        dialing_code: dialingCode,
        number: phoneNumber,
      },
    });
  };

  useEffect(() => {
    dialingCode !== store.value.phone.dialing_code &&
      store.setValue({
        ...store.value,
        phone: {
          ...store.value.phone,
          dialing_code: dialingCode,
        },
      });
  }, [dialingCode, store]);

  return (
    <Grid container>
      <Grid item>
        <PhoneInput
          label={t('Phone number')}
          dialingCode={store.value.phone.dialing_code}
          value={store.value.phone.number}
          setValue={changePhoneNumber}
          editable={editable}
        />
      </Grid>
      <Grid item>
        <DefaultInput label={t('Email')} value={store.value.email} setValue={changeEmail} editable={editable} />
      </Grid>
    </Grid>
  );
};

export default observer(ContactForm);
