import * as React from 'react';
import { observer } from 'mobx-react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { IconButton, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Grid } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import drawerItems from './DrawerItems';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRoles } from '../../cores/decorators';

interface Props extends WithTranslation, RouteComponentProps {
  open: boolean;
  handleDrawerClose: () => void;
}

class CustomerLink extends React.Component<any> {
  render() {
    return <Link {...this.props} to={this.props.itemProp} />;
  }
}

@observer
class DrawerMenu extends React.Component<Props> {
  renderLink = (props: any) => {
    return <Link {...props} to={props.itemProp} />;
  };

  isMatch = (path: string) => {
    return this.props.location.pathname === path;
  };

  render() {
    const { open, handleDrawerClose, t } = this.props;

    return (
      <Drawer variant="persistent" anchor="left" open={open}>
        <Grid>
          <IconButton aria-label="Open drawer" color="secondary" onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </Grid>
        <Divider />
        <List component="nav">
          {drawerItems.map(drawerItem => {
            const { icon, name, path, roles = [] } = drawerItem;
            const isSelected = this.isMatch(path);
            const Items = withRoles(roles)(() => (
              <ListItem component={CustomerLink} selected={isSelected} itemProp={path} onClick={handleDrawerClose}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={t(name)} />
              </ListItem>
            ));
            return <Items key={drawerItem.path} />;
          })}
        </List>
      </Drawer>
    );
  }
}

export default withTranslation()(withRouter(DrawerMenu));
