import { Box, Button, Chip, IconButton, InputBase, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Cached, CheckCircle, Face as FaceIcon, RemoveCircle, Search as SearchIcon } from '@material-ui/icons';
import { ShopStatus } from '@mtt-nails/consts';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { colors } from '../../../themes/theme';
import { Alert, Breadcrumbs } from '../../../components';
import { createControl } from '../../../components/FormControl';
import AppMap from '../../AppMap';
import Store from './Store';

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  shopName: {
    marginLeft: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  btn: {
    minWidth: theme.spacing(10),
    margin: theme.spacing(),
  },
  input: {
    marginLeft: theme.spacing(),
    flex: 1,
    width: theme.spacing(18),
  },
  iconButton: {
    padding: theme.spacing(),
  },
  searchBox: {
    display: 'inline-block',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    height: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    width: 195,
    marginTop: theme.spacing(),
  },
  shopOwner: {
    fontSize: theme.typography.h6.fontSize,
  },
  chip: {
    marginLeft: theme.spacing(),
  },
  icon: { verticalAlign: 'middle' },
  green: {
    color: colors.green,
  },
  blue: {
    color: colors.blue,
  },
}));

interface OwnerProps {
  onDelete: (id: string) => void;
  id: string;
  label: string;
}
const Owner: React.FC<OwnerProps> = ({ onDelete, id, label }) => {
  const classes = useStyles();
  const handleDelete = () => {
    onDelete(id);
  };
  return <Chip className={classes.chip} icon={<FaceIcon />} label={label} onDelete={handleDelete} />;
};

const Detail: React.FC<Props> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { shopId } = useParams();
  const store = useMemo(() => new Store(), []);

  const [owner, setOwner] = useState('');

  const statusRender = (status: string) => {
    switch (status) {
      case ShopStatus.Verified:
        return <CheckCircle className={classNames(classes.icon, classes.green)} />;
      case ShopStatus.Removed:
        return <RemoveCircle className={classes.icon} />;
      case ShopStatus.Created:
        return <Cached className={classNames(classes.icon, classes.blue)} />;
      default:
        return null;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOwner(e.target.value);
  };
  const handleSearch = (e: any) => {
    e.preventDefault();
    store.getAccount(owner);
    setOwner('');
  };

  useEffect(() => {
    shopId && store.fetch(shopId);
  }, [shopId, store]);

  return (
    <Box>
      <Breadcrumbs items={[{ name: 'List', url: AppMap.ShopManagement }, { name: store.child }]} />
      <Paper className={classes.paper}>
        {store.shopName && (
          <Typography variant="h5" className={classes.shopName}>
            {store.shopName} {statusRender(store.shop.status)}
          </Typography>
        )}
        {store.items.map(item =>
          createControl({
            item,
            editable: true,
            setCca2: store.setCca2,
            dialingCode: store.dialingCode,
            cca2: store.countryCode,
          }),
        )}
        <Box pl={1} pt={2}>
          <Typography className={classes.shopOwner}>{t('Shop Owners Information')}</Typography>
          <Box>
            <Box className={classes.searchBox}>
              <form onSubmit={handleSearch}>
                <InputBase
                  value={owner}
                  className={classes.input}
                  placeholder={t('Search Account')}
                  onChange={handleChange}
                />
                <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </form>
            </Box>
            <Box display="inline-block" marginLeft={2}>
              {store.owners.map(owner => (
                <Owner key={owner.id} id={owner.id} label={owner.name} onDelete={store.deleteOwner} />
              ))}
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            disabled={!store.isDirty}
            onClick={store.handleSubmit}>
            {shopId ? t('Update') : t('Create')}
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            onClick={store.resetForm}
            disabled={!store.isDirty}>
            {t('RESET')}
          </Button>
        </Box>
      </Paper>
      <Alert store={store.alertStore} onClose={store.hideAlert} />
    </Box>
  );
};

export default observer(Detail);
