/* eslint-disable no-use-before-define */
import React from 'react';
import { withStyles, WithStyles, Theme, createStyles, Grid, Typography, MenuItem, TextField } from '@material-ui/core';
import { InlineDatePicker, MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Observer, observer } from 'mobx-react';

import WeekItem from './WeekItem';

export enum DisplayMode {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

interface Props extends WithTranslation, WithStyles<typeof styles> {
  date: Date;
  mode?: DisplayMode;
  onDateChange: (date: Date) => void;
  onModeChange?: (mode: React.ChangeEvent<HTMLInputElement>) => void;
}

interface Params extends WithStyles<typeof styles> {
  label: string;
  value: Date;
  onChange: (date: Date) => void;
}

const InLineDate = (props: Params) => {
  const { label, value, onChange, classes } = props;
  return (
    <Grid item className={classes.item}>
      <Typography className={classes.label}>{label}</Typography>
      <Observer>
        {() => (
          <InlineDatePicker
            className={classes.inpuStyle}
            variant="outlined"
            value={value}
            onChange={onChange}
            format="dd/MM/yyyy"
          />
        )}
      </Observer>
    </Grid>
  );
};

@observer
class DateGroup extends React.Component<Props> {
  renderYear = () => {
    const { classes, date, onDateChange } = this.props;
    return (
      <Grid item className={classes.item}>
        <DatePicker views={['year']} variant="outlined" value={date} onChange={onDateChange} autoOk={true} />
      </Grid>
    );
  };
  renderMonth = () => {
    const { classes, date, onDateChange } = this.props;
    return (
      <Grid item className={classes.item}>
        <DatePicker views={['year', 'month']} variant="outlined" value={date} onChange={onDateChange} autoOk={true} />
      </Grid>
    );
  };
  renderDate = (type?: string) => {
    const { classes, date, t, onDateChange } = this.props;

    switch (type) {
      case DisplayMode.Day:
        return <InLineDate label={''} value={date} onChange={onDateChange} classes={classes} />;
      case DisplayMode.Week:
        return <WeekItem date={date} onWeekChange={onDateChange} />;
      case DisplayMode.Month:
        return this.renderMonth();
      case DisplayMode.Year:
        return this.renderYear();
      default:
        return (
          <Grid item className={classes.group}>
            <InLineDate label={t('Từ')} value={date} onChange={onDateChange} classes={classes} />
            <InLineDate label={t('Đến')} value={date} onChange={onDateChange} classes={classes} />
          </Grid>
        );
    }
  };

  render() {
    const { classes, t, onModeChange, mode } = this.props;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="center" spacing={4} className={classes.dateGroup}>
          {mode && (
            <Grid item className={classes.item}>
              <Typography className={classes.label}>{t('Thống kê')}</Typography>
              <Observer>
                {() => (
                  <TextField select value={mode} onChange={onModeChange} variant="outlined">
                    {Object.values(DisplayMode).map(type => (
                      <MenuItem key={type} value={type}>
                        {t(`Statistic.${type}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Observer>
            </Grid>
          )}

          {this.renderDate(mode)}
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    dateGroup: {
      marginBottom: theme.spacing(2),
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },
    label: {
      marginRight: 5,
      fontWeight: 'bold',
    },
    inpuStyle: {
      flex: 1,
      borderRadius: theme.spacing(1),
    },
    group: {
      display: 'flex',
    },
  });
export default withTranslation()(withStyles(styles)(DateGroup));
