import React, { ReactNode } from 'react';
import { TextField, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { observer } from 'mobx-react';
import { IFormItem } from '../interface';

export interface InputProps {
  label: string;
  data: IFormItem<string>;
  type?: string;
  autoComplete?: string;
  disabled?: boolean;
  select?: boolean;
  className?: any;
  inputProps?: OutlinedInputProps['inputProps'];
  InputProps?: Partial<OutlinedInputProps>;
  children?: ReactNode;
}

const useStyles = makeStyles(() => ({
  input: {
    width: 195,
  },
}));

const Input: React.FC<InputProps> = (props: InputProps) => {
  const classes = useStyles();
  const { data, children, select = false, ...others } = props;
  return (
    <Grid item>
      <TextField
        className={classes.input}
        variant={'outlined'}
        select={select}
        onChange={e => data.setValue(e.target.value)}
        value={data.value}
        {...others}>
        {!!children && children}
      </TextField>
    </Grid>
  );
};

export default observer(Input);
