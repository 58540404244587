import { Support, supportApi } from '@mtt-nails/apis/dist/v3';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { action, autorun, computed, observable, reaction, runInAction, toJS } from 'mobx';

import { AddressForm, ContactForm, DefaultForm, Form, TimeForm } from '../../../components/FormControl/Store';
import { BaseStore } from '../../../cores';
import i18n from '../../../i18n';

export default class extends BaseStore {
  @observable shop: Support.ShopBase = Support.ShopBase.from({});
  @observable countryCode: CountryCode = 'DE';
  @observable dialingCode: string;
  @observable items: Form[] = [];
  @observable owners: Support.Profile[] = [];
  @observable _owners: Support.Profile[] = [];

  changeShop = autorun(() => this.init(this.shop));

  @computed
  get shopName() {
    return this.shop.name;
  }
  @computed
  get shopId() {
    return this.shop.id;
  }
  @computed
  get child() {
    return this.shop.name || 'Create';
  }
  @computed
  get ownerDirty() {
    return JSON.stringify(toJS(this.owners)) !== JSON.stringify(this._owners);
  }
  @computed
  get isDirty() {
    return this.items.some(item => item.isDirty) || this.ownerDirty;
  }

  @action changeDialingCode = reaction(
    () => this.countryCode,
    code => {
      const dialingCode = getCountryCallingCode(code);
      this.dialingCode = `+${dialingCode}`;
    },
  );
  @action setCca2 = (code: string) => (this.countryCode = code as CountryCode);
  @action init = ({ name, openingTimes, address, contact }: Support.ShopBase) => {
    this.items = [];
    this.items.push(new DefaultForm({ key: 'name', label: 'Name', value: name }));
    this.items.push(
      new TimeForm({ key: 'openingTimes', label: 'Opening Times', value: Support.OpeningTimes.from(openingTimes) }),
    );
    this.items.push(new AddressForm({ key: 'address', label: 'Address', value: Support.Address.from(address) }));
    this.items.push(new ContactForm({ key: 'contact', label: 'Contact', value: contact }));

    if (address.country) {
      const code = address.country as CountryCode;
      this.countryCode = code;
      const dialingCode = `+${getCountryCallingCode(code)}`;
      this.dialingCode = dialingCode;
      const idx = this.items.findIndex(i => i.key === 'contact');
      if (idx) {
        const contact = this.items[idx];
        const val = Support.Contact.from(contact.value);
        val.phone.dialing_code = dialingCode;
        contact.setValue({ ...val });
        contact.setDefaultValue({ ...val });
      }
    }
  };
  @action resetForm = () => {
    this.items.forEach(item => item.resetValue());
    this.owners = this._owners;
  };
  @action deleteOwner = (id: string) => {
    const idx = this.owners.findIndex(owner => owner.id === id);
    if (idx >= 0) {
      this.owners.splice(idx, 1);
    }
  };

  fetch = async (shopId: string) => {
    try {
      const [shop, owners] = await Promise.all([supportApi.getShopById(shopId), supportApi.getShopOwner(shopId)]);
      runInAction(() => {
        this.shop = shop.data;
        this.owners = owners.data;
        this._owners = owners.data;
      });
    } catch (error) {
      this.setError(error);
    }
  };
  getAccount = async (email: string) => {
    try {
      const { data } = await supportApi.findOneAccount({ email });
      data &&
        runInAction(() => {
          const idx = this.owners.findIndex(owner => owner.id === data.id);
          if (idx < 0) {
            this.owners = [...this.owners, data];
          }
        });
    } catch (error) {
      this.setError(error);
    }
  };
  @action
  handleSubmit = async () => {
    try {
      const _data: any = {};
      if (this.shopId) {
        this.items.forEach(item => {
          if (item.isDirty) {
            _data[item.key] = item.value;
          }
        });
        _data['id'] = this.shopId;
        if (this.ownerDirty) {
          await Promise.all([supportApi.updateShopInfo(_data), supportApi.updateShopOwners(this.shopId, this.owners)]);
          runInAction(() => {
            this._owners = this.owners;
          });
        } else {
          await supportApi.updateShopInfo(_data);
        }
        this.items.forEach(item => item.updateValue());
      } else {
        this.items.forEach(item => {
          _data[item.key] = item.value;
        });
        const { data } = await supportApi.createShop(_data);
        const shopOwners = this.owners.map(owner => ({ id: owner.id, name: owner.name }));
        await supportApi.updateShopOwners(data.id, shopOwners);
        this.resetForm();
      }
      this.setSuccess(i18n.t('Success'));
    } catch (error) {
      this.setError(error);
    }
  };
}
