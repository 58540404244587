import * as React from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';

interface Props {
  title: string | React.ReactNode;
  defaultExpanded?: boolean;
}

class ReportPanel extends React.PureComponent<Props> {
  render() {
    const { title, defaultExpanded = true, children } = this.props;
    return (
      <React.Fragment>
        <ExpansionPanel defaultExpanded={defaultExpanded}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreRounded />}>
            {typeof title === 'string' ? (
              <Typography variant="h6" color="inherit">
                {title}
              </Typography>
            ) : (
              title
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  }
}

export default ReportPanel;
