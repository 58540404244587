import { Support } from '@mtt-nails/apis/dist/v3';
import { SortType, TableConfig } from '../../../../../components/Table/Store';
import { utils } from '../../../../../cores';

const relationTableConfig: TableConfig<Support.ShopUserRelation> = {
  displayName: 'Shop Relation',
  items: [],
  columns: [
    {
      key: 'name',
      header: 'Shop',
      valueGetter: (row: Support.ShopUserRelation) => row.shopName,
      sort: (val1: Support.ShopUserRelation, val2: Support.ShopUserRelation, order: SortType) =>
        utils.Sortable.sortByString(val1.shopName, val2.shopName, order),
    },
    {
      key: 'id',
      header: 'ID',
      valueGetter: (row: Support.ShopUserRelation) => `${row.id}`,
      sort: (val1: Support.ShopUserRelation, val2: Support.ShopUserRelation, order: SortType) =>
        utils.Sortable.sortByString(`${val1.id}`, `${val2.id}`, order),
    },
    {
      key: 'roles',
      header: 'Roles',
      valueGetter: (row: Support.ShopUserRelation) => utils.translate(`${row.relation}`),
      sort: (val1: Support.ShopUserRelation, val2: Support.ShopUserRelation, order: SortType) =>
        utils.Sortable.sortByString(`${val1.relation}`, `${val2.relation}`, order),
    },
  ],
};

export default relationTableConfig;
