import { observable, action } from 'mobx';

export default class {
  @observable
  open = false;

  @observable
  isToggle = false;

  @observable
  anchorEl = false;

  @observable
  mobileMoreAnchorElToggle = false;

  @action
  setOpen = (open: boolean) => {
    this.open = open;
  };

  @action
  setToggle = () => {
    this.isToggle = !this.isToggle;
  };

  @action
  setClosed = () => {
    this.isToggle = false;
  };

  @action
  setToggleMobile = () => {
    this.mobileMoreAnchorElToggle = !this.mobileMoreAnchorElToggle;
  };

  @action
  setClosedMobile = () => {
    this.mobileMoreAnchorElToggle = false;
  };
}
