import React from 'react';
import { useTranslation } from 'react-i18next';
import { reaction } from 'mobx';
import { observer, Observer } from 'mobx-react';
import { getCountryCallingCode, CountryCode } from 'libphonenumber-js';
import { Grid, makeStyles, createStyles, Theme, Button } from '@material-ui/core';
import Store from '../../Store';
import { ReportPanel, FormComponents } from '../../../../components';
import { Address } from '../../../../components/FormComponents/Groups/AddressControl';
import { Contact } from '../../../../components/FormComponents/Groups/ContactControl';
import { Name } from '../../../../components/FormComponents/Groups/NameControl';

interface Props {
  store: Store;
}

const UserInformation: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const classes = styles();
  reaction(
    () => (props.store.userStore.form.items.address as Address).form.items['country'].value,
    country => {
      (props.store.userStore.form.items.contact as Contact).setDialingCode(
        `+${getCountryCallingCode(country as CountryCode)}`,
      );
    },
  );
  return (
    <Grid className={classes.margin}>
      <ReportPanel title={t('Personal Information')}>
        <Grid container spacing={4} className={classes.container}>
          <Observer>
            {() => <FormComponents.Groups.NameControl data={props.store.userStore.form.items['name'] as Name} />}
          </Observer>
          <Observer>
            {() => (
              <FormComponents.Groups.AddressControl data={props.store.userStore.form.items['address'] as Address} />
            )}
          </Observer>
          <Observer>
            {() => (
              <FormComponents.Groups.ContactControl data={props.store.userStore.form.items['contact'] as Contact} />
            )}
          </Observer>
          <Grid item xs={12}>
            <Observer>
              {() => (
                <>
                  <Button
                    variant="contained"
                    disabled={!props.store.userStore.isEditable}
                    onClick={props.store.onSaveUserInfomation}
                    className={classes.button}>
                    {t('SAVE')}
                  </Button>
                </>
              )}
            </Observer>
            <Button variant="contained" onClick={props.store.userStore.onCancel} className={classes.button}>
              {t('RESET')}
            </Button>
          </Grid>
        </Grid>
      </ReportPanel>
    </Grid>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    container: {
      paddingTop: 15,
    },
    button: {
      marginRight: theme.spacing(1),
    },
  }),
);

export default observer(UserInformation);
