import { Box, Button } from '@material-ui/core';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';
import { reaction } from 'mobx';
import { useLocalStore, Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Alert, Table } from '../../../components';
import DataTable, { ActionType } from '../../../components/Table/Store';
import { AddUserModal } from '../components';
import Store from './store';
import tableConfig from './tableConfig';

const store = new Store();

const List = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleDoubleClickRow = (row: Support.UserBase) => {
    history.push(`/staff-management/edit/${row.id}`, { shopName: row.shopName });
  };

  const dataTable: DataTable<Support.UserBase> = useLocalStore(
    () =>
      new DataTable({
        ...tableConfig,
        items: store.items,
        actionsCol: {
          label: 'Actions',
          actions: [
            {
              name: ActionType.EDIT,
              onClick: handleDoubleClickRow,
            },
            {
              name: ActionType.DELETE,
              onClick: (row: Support.UserBase) => store.handleDeleteUser(row.id),
            },
          ],
        },
      }),
  );

  useEffect(() => {
    store.fetch();
  }, []);
  useEffect(() => {
    dataTable.fetchRemote(supportApi.getShopUsers);
  }, [dataTable]);

  reaction(
    () => store.shops,
    shops => {
      dataTable.setFilterable({
        selectedValue: '',
        items: [{ label: t('All shops'), value: '' }, ...shops.map(shop => ({ label: shop.name, value: shop.id }))],
        filter: (user: Support.UserBase) => dataTable.filterable.selectedValue === user.shopId,
      });
    },
  );
  reaction(
    () => store.items,
    items => {
      dataTable.setItems(items);
    },
  );

  return (
    <Box>
      <Box my={2}>
        <Button color="secondary" variant="contained" onClick={store.showModalAddVisible}>
          {t('Add Staff')}
        </Button>
      </Box>
      <Table data={dataTable} onDoubleClickRow={handleDoubleClickRow} />
      <Observer>{() => <AddUserModal open={store.modalAddVisible} onClose={store.hideModalAddVisible} />}</Observer>
      <Alert store={store.alertStore} onClose={store.hideAlert} />
    </Box>
  );
};

export default List;
