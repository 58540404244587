import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter, matchPath, RouteComponentProps, RouteProps, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { StoreNames, RootStore } from '../stores';
import isNull from 'lodash/isNull';
import AppMap from '../screens/AppMap';

interface Props extends RouteComponentProps, RootStore {
  routes: RouteProps[];
}

@observer
class Authentication extends Component<Props> {
  canAccess() {
    return !this.props[StoreNames.Authorization].isAuthenticated;
  }

  isAuthenticationRoute() {
    const { routes, location } = this.props;
    return routes.some(route => !isNull(matchPath(location.pathname, route)));
  }

  render() {
    if (this.canAccess() && this.isAuthenticationRoute()) {
      return this.props.routes.map(route => <Route key={`${route.path}`} {...route} />);
    }
    if (this.isAuthenticationRoute()) {
      return <Redirect to={AppMap.Dashboard} />;
    }
    return null;
  }
}

export default withRouter(Authentication);
