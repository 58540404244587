import React from 'react';
import { observer } from 'mobx-react';
import { appbarStyles } from './styles';
import { withStyles, WithStyles, Grow, ClickAwayListener, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation, WithStyles<typeof appbarStyles> {
  open: boolean;
  onClickAway: any;
  anchorEl: HTMLElement;
  logout: any;
  navigateProfile: any;
}

@observer
class ProfileMenu extends React.Component<Props> {
  render() {
    const { open, onClickAway, anchorEl, logout, navigateProfile, t } = this.props;

    return (
      <Popper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList>
                  <MenuItem onClick={navigateProfile}>{t('Profile')}</MenuItem>
                  <MenuItem onClick={logout}>{t('Logout')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  }
}

export default withTranslation()(withStyles(appbarStyles)(ProfileMenu));
