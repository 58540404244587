import { Button, Paper, Popover } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Support } from '@mtt-nails/apis/dist/v3';

import DateRow from './DateRow';

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    margin: theme.spacing(2),
    minWidth: 195,
    marginLeft: theme.spacing(2),
  },
  paper: {
    minWidth: theme.spacing(50),
    padding: theme.spacing(2),
    maxHeight: theme.spacing(50),
  },
  btnAdd: {
    alignContent: 'flex-end',
  },
}));

interface IProps {
  disabled: boolean;
  selectedDate: Support.SpecialWorkDay[];
  onAddSpecialDay: (day: Support.SpecialWorkDay[]) => void;
  onDeleteSpecialDay: (day: Support.SpecialWorkDay[]) => void;
}

const id = 'popover-specialDate';

const SpecialDay: React.FC<IProps> = ({ disabled, selectedDate, onAddSpecialDay, onDeleteSpecialDay }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [from, setFrom] = useState<null | Date>(null);
  const [to, setTo] = useState<null | Date>(null);
  const [listDate, setListDate] = useState<{ from: Date | null; to: Date | null }[]>(selectedDate);

  useEffect(() => {
    setListDate(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    setOpen(Boolean(anchorEl));
  }, [anchorEl]);

  const handleOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const handleAdd = useCallback(() => {
    if (from && to) {
      onAddSpecialDay([{ from, to }]);
    }
    setFrom(null);
    setTo(null);
  }, [from, to, setFrom, setTo, onAddSpecialDay]);
  const handleCancel = useCallback(() => {
    setFrom(null);
    setTo(null);
  }, [setFrom, setTo]);

  return (
    <React.Fragment>
      <Button aria-describedby={id} className={classes.btn} onClick={handleOpen} disabled={disabled} variant="outlined">
        {t('Special day')}
      </Button>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Paper className={classes.paper}>
          {listDate.map(list => (
            <DateRow
              key={list.from?.toString()}
              from={list.from}
              to={list.to}
              disabled={true}
              onDeleteSpecialDay={onDeleteSpecialDay}
            />
          ))}
          <DateRow from={from} fromChange={setFrom} to={to} toChange={setTo} onCancel={handleCancel} />
          <Button
            color="primary"
            variant="outlined"
            className={classes.btnAdd}
            onClick={handleAdd}
            disabled={!from || !to}>
            {t('Add')}
          </Button>
        </Paper>
      </Popover>
    </React.Fragment>
  );
};

export default SpecialDay;
