import { observable, action, runInAction, reaction } from 'mobx';
import { BaseStore } from '../cores';
import { IAsyncTracking } from './AsyncTracking';
import i18n from '../i18n';
import { supportApi } from '@mtt-nails/apis/dist/v3';

export default class LanguageStore extends BaseStore implements IAsyncTracking {
  @observable
  locale = 'vi';

  appSupportedLanguages = [
    { language: 'English', code: 'en' },
    { language: 'Deutsch', code: 'de' },
    { language: 'Tiếng Việt', code: 'vi' },
  ];

  constructor() {
    super();
    this.preload();
    reaction(
      () => this.locale,
      locale => i18n.changeLanguage(locale),
    );
  }

  load = () => this.preload();

  @action
  preload = async () => {
    await this.loadLocalLocale();
    await this.loadLanguage();
  };

  @action
  loadLanguage = async () => {
    try {
      const remoteTranslationResource = await supportApi.getLanguage(this.locale);
      i18n.addResources(this.locale, 'remote', remoteTranslationResource.data);
      i18n.changeLanguage(this.locale);
    } catch (error) {
      this.debug('Can not load language');
    }
  };

  @action
  setLocale = async (locale: string) => {
    try {
      i18n.removeResourceBundle(this.locale, 'remote');
      this.locale = locale;
      this.loadLanguage();
      await localStorage.setItem('locale', this.locale);
    } catch (err) {
      this.debug(err);
    }
  };

  @action
  loadLocalLocale = async () => {
    try {
      const localLocale = await localStorage.getItem('locale');
      runInAction(() => {
        if (localLocale) {
          this.locale = localLocale;
        }
      });
    } catch (err) {
      this.debug(err);
    }
  };
}
