import { observable, computed, action } from 'mobx';
import { BaseStore } from '../../cores';
import { Form } from '../../components/FormComponents/helper';
import { Password } from '../../components/FormComponents/Groups/ChangePasswordControl';

class PasswordStore extends BaseStore {
  @observable
  form: Form;

  @observable
  alreadyEdit = () => this.form.isDirty();

  @computed
  get isEditable() {
    return this.validate() && this.alreadyEdit();
  }

  constructor() {
    super();
    this.form = new Form();
    this.form.items['password'] = new Password();
  }

  validate(): boolean {
    return this.form.isValid();
  }

  @action
  reset = () => {
    this.form.onReset();
    this.alreadyEdit = () => this.form.isDirty();
  };

  @action
  onCancel = () => {
    this.form.onReset();
  };
}

export default PasswordStore;
