import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const colors = {
  transparent: 'transparent',
  pink: '#e54f7c',
  pink80: '#f2a6bd',
  white: '#fff',
  white_6: 'rgba(255, 255, 255, 0.6)',
  green: 'green',
  gray: '#808080',
  gray67: '#676767',
  gray75: '#757575',
  gray60: '#979797',
  gray90: '#ECECEC',
  gray_8: 'rgba(212, 212, 212, 0.8)',
  gray_6: 'rgba(128, 128, 128, 0.6)',
  black: '#000',
  black_6: 'rgba(0, 0, 0, 0.6)',
  blue: '#2A72CA',
  blue2: '#1B2153',
  blue3: '#313862',
  blue4: '#333b79',
  blue5: '#3f3777',
  blue6: '#2B5E97',
  primary: '#3897f0',
  red: '#e90404',
  silver: '#C9C9C9',
  purple: '#826FE1',
  shadow: '#910019',
  lightgray: 'lightgray',
  lightgrayish: '#F5F5F5',
  pinkButton: '#E54F7C',
  pinkLabel: '#C2003C',
};

const drawerWidth = 240;

const theme: ThemeOptions = {
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: colors.pink,
        color: colors.white,
        '&$disabled': {
          color: colors.gray,
        },
        '&:hover': {
          backgroundColor: colors.pink80,
          color: colors.white,
        },
      },

      outlined: {
        borderColor: colors.pink,
        backgroundColor: colors.transparent,
        '&:hover': {
          backgroundColor: colors.pink80,
          color: colors.white,
        },
      },
      text: {
        color: colors.black,
        backgroundColor: colors.transparent,
        '&:hover': {
          backgroundColor: colors.transparent,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 48,
        '&$focused $notchedOutline': {
          borderColor: colors.blue,
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: colors.pink,
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        display: 'block',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        '&$expanded': {
          height: 24,
        },
        backgroundColor: colors.blue,
        color: colors.white,
        alignItems: 'center',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        height: 48,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCardContent: {
      root: {
        backgroundColor: colors.white,
        color: colors.black,
      },
    },
    MuiDrawer: {
      paper: {
        width: drawerWidth,
        backgroundColor: colors.blue2,

        '& > div': {
          '&:first-child': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
          },
        },
      },
      docked: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    MuiTypography: {
      colorSecondary: {
        color: colors.pink,
      },
      body2: {
        color: colors.red,
      },
      subtitle1: {
        color: colors.white,
      },
    },
    MuiIconButton: {
      colorSecondary: {
        color: colors.white,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.blue3,
      },
    },
    MuiList: {
      root: {
        paddingLeft: '10px',
      },
    },
    MuiListItem: {
      root: {
        padding: '10px',
        borderTopLeftRadius: '50px',
        borderBottomLeftRadius: '50px',

        '&:hover': {
          backgroundColor: colors.blue4,
        },

        '&$selected': {
          backgroundColor: colors.blue5,
          color: '#fff',
          '& > div': {
            color: colors.blue6,
          },
        },
        '&$selected:hover': {
          backgroundColor: colors.blue4,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: colors.white,
      },
    },
    MuiListItemText: {
      root: {
        padding: 0,
      },
      primary: {
        color: colors.white,
      },
    },
  },
};

export default theme;
