import { observable, computed, runInAction, action } from 'mobx';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';
import { StaffStatus } from '@mtt-nails/consts';

import BaseStore from '../../../cores/BaseStore';

export default class extends BaseStore {
  @observable loading = false;
  @observable allShops: Support.ShopBase[];
  @observable items: Support.UserBase[] = [];
  @observable modalAddVisible: boolean;

  @computed
  get shops() {
    return this.allShops;
  }

  @action showModalAddVisible = () => (this.modalAddVisible = true);
  @action hideModalAddVisible = () => (this.modalAddVisible = false);
  @action
  handleDeleteUser = async (id: string) => {
    runInAction(() => {
      this.loading = true;
    });
    try {
      await supportApi.updateShopUser({
        id,
        status: StaffStatus.Delete,
      });
      const idx = this.items.findIndex(item => item.id === id);
      if (idx >= 0) {
        const clone = [...this.items];
        const row = clone[idx];
        row.status = StaffStatus.Delete;
        clone.splice(idx, 1, row);
        runInAction(() => {
          this.items = [...clone];
        });
      }
    } catch (error) {
      this.setError(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  fetch = async () => {
    try {
      const [{ data: allShops }, { data: shopUsers }] = await Promise.all([
        supportApi.getShops(),
        supportApi.getShopUsers(),
      ]);
      runInAction(() => {
        this.allShops = allShops;
        this.items = shopUsers;
      });
    } catch (error) {
      this.setError(error);
    }
  };
}
