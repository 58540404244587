import { observable, computed, action } from 'mobx';
import { Support } from '@mtt-nails/apis/dist/v3';
import { BaseStore } from '../../cores';
import { Form } from '../../components/FormComponents/helper';
import { Address } from '../../components/FormComponents/Groups/AddressControl';
import { Contact } from '../../components/FormComponents/Groups/ContactControl';
import { Name } from '../../components/FormComponents/Groups/NameControl';

class UserStore extends BaseStore {
  @observable
  form: Form;

  @observable
  alreadyEdit = () => this.form.isDirty();

  @computed
  get isEditable() {
    return this.validate() && this.alreadyEdit();
  }

  constructor(data: Support.Profile = Support.Profile.from({})) {
    super();
    this.form = new Form();
    this.form.items['name'] = new Name({ firstName: data.firstName, lastName: data.lastName });
    this.form.items['address'] = new Address(data.address);
    this.form.items['contact'] = new Contact({ phone: data.phone, email: data.email });
  }

  validate(): boolean {
    return this.form.isValid();
  }

  @action
  reset = () => {
    this.form.onSetDefault();
    this.alreadyEdit = () => this.form.isDirty();
  };

  @action
  onCancel = () => {
    this.form.onReset();
  };
}

export default UserStore;
