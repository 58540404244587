import React from 'react';
import { observer } from 'mobx-react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { observable, runInAction } from 'mobx';
import { useTranslation } from 'react-i18next';
import { ShopRelationType } from '@mtt-nails/consts';

export class RoleSelectStore {
  @observable
  isOpen = false;

  @observable
  title: string;

  @observable
  value: string;
}

interface Props {
  relationId: string;
  store: RoleSelectStore;
  onSubmit(relationId: string, relationName: string): void;
  onClose(): void;
}

const RoleSelect: React.FC<Props> = (props: Props) => {
  const { store, onClose, onSubmit } = props;
  const { t } = useTranslation();
  const classes = styles();

  const handleChange = (value: string) => {
    runInAction(() => {
      store.value = value;
    });
  };

  return (
    <>
      <Dialog open={store.isOpen} onClose={onClose}>
        <DialogTitle>{t(store.title)}</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <Select value={store.value} onChange={event => handleChange(event.target.value as string)}>
              {Object.keys(ShopRelationType).map(e => (
                <MenuItem value={e} key={e}>
                  {t(e)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onSubmit(props.relationId, store.value)} color="primary">
            {t('CONFIRM')}
          </Button>
          <Button onClick={onClose} color="primary" autoFocus>
            {t('CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

export default observer(RoleSelect);
