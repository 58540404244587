import { action, observable, runInAction } from 'mobx';
import { BaseStore } from '../../../cores';
import { supportApi } from '@mtt-nails/apis/dist/v3';
import i18n from '../../../i18n';

class Store extends BaseStore {
  @observable
  isUpdateUI = false;

  @action
  removeUser = async (userId: string) => {
    try {
      await supportApi.removeUser(userId);
      runInAction(() => {
        this.setSuccess(i18n.t('Success'));
        this.isUpdateUI = true;
      });
    } catch (err) {
      runInAction(() => {
        this.setError(err);
      });
    }
  };
}

export default Store;
