import { observer, useLocalStore } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { Alert, Breadcrumbs, Modal, Tabs } from '../../../components';
import AppMap from '../../AppMap';
import { ChangePassword, ShopRelation, UserInformation } from './components';
import Store from './Store';

interface Param {
  userId: string;
}

interface Props extends RouteComponentProps<Param> {
  userId: string;
}

const child = { name: 'Account' };

const Edit: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const store: Store = useLocalStore(() => new Store(props.match.params.userId));

  const options = [
    { label: t('Personal Information'), component: <UserInformation store={store.userStore} /> },
    { label: t('Change password'), component: <ChangePassword onSubmit={store.submitPassword} /> },
    { label: t('Shop Relation'), component: <ShopRelation userId={props.match.params.userId} store={store} /> },
  ];

  return (
    <>
      <Breadcrumbs items={[{ name: 'List', url: AppMap.UserManagement }, child]} />
      <Tabs withPaper options={options} />
      <Modal store={store.modalStore} onClose={store.closeModal} onSubmit={store.submitForm} />
      <Alert store={store.alertStore} onClose={store.hideAlert} />
    </>
  );
};

export default observer(Edit);
