import React from 'react';
import { useTranslation } from 'react-i18next';
import { reaction } from 'mobx';
import { observer, Observer, useLocalStore } from 'mobx-react';
import { getCountryCallingCode, CountryCode } from 'libphonenumber-js';
import { Grid, makeStyles, Theme, createStyles, Button } from '@material-ui/core';
import UserStore from './UserStore';
import AppMap from '../../AppMap';
import { ReportPanel, Breadcrumbs, Alert, Modal, FormComponents } from '../../../components';
import { Address } from '../../../components/FormComponents/Groups/AddressControl';
import { Contact } from '../../../components/FormComponents/Groups/ContactControl';
import { Name } from '../../../components/FormComponents/Groups/NameControl';
import { Password } from '../../../components/FormComponents/Groups/PasswordControl';
import { Role } from '../../../components/FormComponents/Groups/RoleControl';

const Signup: React.FC = () => {
  const { t } = useTranslation();
  const classes = styles();

  const store: UserStore = useLocalStore(() => new UserStore());

  reaction(
    () => (store.form.items.address as Address).form.items.country.value,
    country => {
      (store.form.items.contact as Contact).setDialingCode(`+${getCountryCallingCode(country as CountryCode)}`);
    },
  );

  const child = { name: 'Create Account' };

  return (
    <>
      <Breadcrumbs items={[{ name: 'List', url: AppMap.UserManagement }, child]} />
      <Grid className={classes.margin}>
        <ReportPanel title={t('Create Account')}>
          <Grid container spacing={4} className={classes.container}>
            <Observer>{() => <FormComponents.Groups.NameControl data={store.form.items['name'] as Name} />}</Observer>
            <Observer>
              {() => <FormComponents.Groups.AddressControl data={store.form.items['address'] as Address} />}
            </Observer>
            <Observer>
              {() => <FormComponents.Groups.ContactControl data={store.form.items['contact'] as Contact} />}
            </Observer>
            <Observer>
              {() => <FormComponents.Groups.PasswordControl data={store.form.items['password'] as Password} />}
            </Observer>
            <Observer>{() => <FormComponents.Groups.RoleControl data={store.form.items['roles'] as Role} />}</Observer>
            <Grid item xs={12}>
              <Observer>
                {() => (
                  <>
                    <Button
                      variant="contained"
                      disabled={!store.isEditable}
                      onClick={store.onCreateUser}
                      className={classes.button}>
                      {t('Create')}
                    </Button>
                  </>
                )}
              </Observer>
              <Button variant="contained" onClick={store.reset} className={classes.button}>
                {t('RESET')}
              </Button>
              <Modal store={store.modalStore} onClose={store.closeModal} onSubmit={store.submitFormCreateAccount} />
              <Alert store={store.alertStore} onClose={store.hideAlert} />
            </Grid>
          </Grid>
        </ReportPanel>
      </Grid>
    </>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    container: {
      paddingTop: 15,
    },
    button: {
      marginRight: theme.spacing(1),
    },
  }),
);

export default observer(Signup);
