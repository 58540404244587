import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { IFormItem } from '../interface';
import Input from './Input';

interface Props {
  label: string;
  data: IFormItem<string>;
  disabled?: boolean;
}

const PasswordInput: React.FC<Props> = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { data, label, ...others } = props;
  const changeVisible = () => {
    setVisible(!visible);
  };

  return (
    <Input
      type={visible ? 'text' : 'password'}
      label={t(`${label}`)}
      data={data}
      disabled={others.disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton key="eye" color="inherit" onClick={changeVisible}>
              {visible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default observer(PasswordInput);
