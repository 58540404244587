import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Alert } from '../../../../../components';
import { createControl } from '../../../../../components/FormControl';
import UserStore from '../../UserStore';

interface Props {
  store: UserStore;
}

const UserInformation: React.FC<Props> = ({ store }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleEdit = React.useCallback(() => store.setEditable(true), [store]);
  const handleSave = React.useCallback(() => {
    store.setModal({
      isModalOpen: true,
      modalTitle: t('Change personal information'),
      modalMessage: t('The information will be permanently changed. Are you sure to change the information ?'),
    });
  }, [store, t]);
  const handleCancel = React.useCallback(() => {
    store.reset();
    store.setEditable(false);
  }, [store]);

  return (
    <Box>
      {store.items.map(item =>
        createControl({
          item,
          cca2: store.countryCode,
          dialingCode: store.dialingCode,
          editable: store.editable,
          setCca2: store.setCca2,
        }),
      )}
      <Box mt={3}>
        {!store.editable ? (
          <>
            <Button className={classes.button} variant="contained" color="primary" onClick={handleEdit}>
              {t('EDIT')}
            </Button>
          </>
        ) : (
          <>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={!store.isDirty}>
              {t('SAVE')}
            </Button>
            <Button className={classes.button} variant="contained" color="secondary" onClick={handleCancel}>
              {t('Cancel')}
            </Button>
          </>
        )}
      </Box>
      <Modal store={store.modal} onClose={() => store.setModal({ isModalOpen: false })} onSubmit={store.save} />
      <Alert store={store.alertStore} onClose={store.hideAlert} />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    container: {
      paddingTop: 15,
    },
    button: {
      margin: theme.spacing(1),
      minWidth: theme.spacing(20),
    },
  }),
);

export default observer(UserInformation);
