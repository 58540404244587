import { createStyles } from '@material-ui/core';

export default createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  linear: {
    position: 'absolute',
    width: '20%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
