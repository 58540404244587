import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, Observer } from 'mobx-react';
import { Button, createStyles, Theme, makeStyles } from '@material-ui/core';
import Store from './DayOffStore';
import { OffDatesList } from '../helper';
import { PopoverCalendar } from '../../';

interface Props {
  data: OffDatesList;
  disabled?: boolean;
}

const store = new Store();

const SelectDayOffBox: React.FC<Props> = (props: Props) => {
  const { data, ...others } = props;
  const classes = styles();
  const { t } = useTranslation();

  const handleDayClick = (day: Date) => {
    if (!data.value.has(data.format(day.toDateString()))) {
      data.addDate(day);
    }
  };

  const handleDayDelete = (day: Date) => {
    if (data.value.has(data.format(day.toDateString()))) {
      data.removeDate(day);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        className={classes.buttonMargin}
        onClick={store.handleClick}
        disabled={others.disabled}>
        {t('Day off')}
      </Button>
      <Observer>
        {() => (
          <PopoverCalendar
            selectedDays={data.convertToDate()}
            open={store.open}
            anchorEl={store.anchorEl}
            onClose={store.handleClose}
            onDayClick={handleDayClick}
            onDelete={handleDayDelete}
          />
        )}
      </Observer>
    </>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    buttonMargin: {
      margin: theme.spacing(2),
      minWidth: 195,
    },
  }),
);

export default observer(SelectDayOffBox);
