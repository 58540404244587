import { observable, computed, action } from 'mobx';
import { BaseStore } from '../../../cores';

class DayOffStore extends BaseStore {
  @observable
  anchorEl = null;

  @computed
  get open() {
    return Boolean(this.anchorEl);
  }

  @action
  setAnchorEl = (event: any) => {
    this.anchorEl = event;
  };

  @action
  handleClick = (event: any) => {
    this.setAnchorEl(event.currentTarget);
  };

  @action
  handleClose = () => {
    this.setAnchorEl(null);
  };
}

export default DayOffStore;
