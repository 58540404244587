/* eslint-disable no-useless-computed-key */
import { createStyles, Theme } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    logo: {
      width: 100,
      height: 100,
      ['@media screen and (min-height: 500px)']: {
        margin: `5px 0px`,
      },
      ['@media screen and (min-height: 700px)']: {
        margin: `40px 0px`,
      },
    },
  });
