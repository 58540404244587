import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Paper, Avatar } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { red } from '@material-ui/core/colors';

import Store from './store';
import { createControl } from '../../../components/FormControl';

interface Props {
  store: Store;
}

const labelByStatus = {
  Pending: 'Confirm',
  Active: 'Deactive',
  Deactive: 'Active',
  Delete: 'Active',
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: theme.spacing(10),
    margin: theme.spacing(2),
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  right: {
    padding: theme.spacing(3),
  },
  avatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    fontSize: theme.typography.h4.fontSize,
  },
  highlight: {
    color: red['500'],
  },
  typo: {
    marginTop: theme.spacing(),
  },
}));

const PersonalInformation: React.FC<Props> = ({ store }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editable, setEditable] = useState(true);

  const handleCancel = () => {
    store.resetItem();
    setEditable(false);
  };
  const handleSave = () => {
    store.handleUpdate(store.items);
    setEditable(false);
  };

  useEffect(() => {
    setEditable(false);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} md={3}>
        <Paper className={classes.left}>
          <Avatar src={store.user.avatar} className={classes.avatar}>
            {store.user.name.slice(0, 1).toUpperCase()}
          </Avatar>
          <Typography className={classes.typo}>
            {`${t('Role')}: `}
            <span className={classes.highlight}>{t(store.user.relation)}</span>
          </Typography>
          <Typography className={classes.typo}>
            {`${t('Status')}: `}
            <span className={classes.highlight}>{t(store.userStatus)}</span>
          </Typography>
          <Typography className={classes.typo}>
            {`${t('Shop')}: `}
            <span className={classes.highlight}>{t(store.shopName)}</span>
          </Typography>
          <Button variant="contained" color="secondary" className={classes.button} onClick={store.updateStatus}>
            {t(labelByStatus[store.userStatus])}
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={9} md={9}>
        <Paper className={classes.right}>
          {store.items.map(item =>
            createControl({
              item,
              editable,
              setCca2: store.setCca2,
              dialingCode: store.dialingCode,
              cca2: store.countryCode,
            }),
          )}

          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            {!editable ? (
              <Button className={classes.button} variant="contained" color="primary" onClick={() => setEditable(true)}>
                {t('EDIT')}
              </Button>
            ) : (
              <>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={!store.isDirty}>
                  {t('SAVE')}
                </Button>
                <Button className={classes.button} variant="contained" color="secondary" onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default observer(PersonalInformation);
