import { Box, Button, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createControl } from '../../../components/FormControl';
import Store from './store';

interface Props {
  store: Store;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: theme.spacing(10),
    margin: theme.spacing(),
  },
  paper: {
    padding: theme.spacing(3),
  },
}));

const WorkInformation: React.FC<Props> = ({ store }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editable, setEditable] = useState(false);

  const handleCancel = () => {
    store.resetWorkItem();
    setEditable(false);
  };
  const handleSave = () => {
    store.handleUpdate(store.itemWorks);
    setEditable(false);
  };

  return (
    <Box>
      <Paper className={classes.paper}>
        {store.itemWorks.map(item =>
          createControl({
            item,
            editable,
            setCca2: store.setCca2,
            dialingCode: store.dialingCode,
            cca2: store.countryCode,
          }),
        )}

        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          {!editable ? (
            <Button className={classes.button} variant="contained" color="primary" onClick={() => setEditable(true)}>
              {t('EDIT')}
            </Button>
          ) : (
            <>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={!store.isWorkInformationDirty}>
                {t('SAVE')}
              </Button>
              <Button className={classes.button} variant="contained" color="secondary" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default observer(WorkInformation);
