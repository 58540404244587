import { BaseStore } from '../cores';
import { observable, action, runInAction } from 'mobx';
import { roles } from '../cores/decorators';
import { IAsyncTracking } from './AsyncTracking';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';

export default class UserStore extends BaseStore implements IAsyncTracking {
  @roles
  userRoles: string[];

  @observable
  profile: Support.Profile = Support.Profile.from({});

  load = () => this.fetch();

  @action
  fetch = async () => {
    try {
      const { data } = await supportApi.getProfile();
      runInAction(() => {
        this.profile = Support.Profile.from(data);
        this.userRoles = data.roles;
      });
    } catch (error) {
      this.debug(error);
    }
  };

  @action
  clearUser = () => {
    this.userRoles = [];
    this.profile = Support.Profile.from({});
  };
}
