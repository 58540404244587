import { IForm, IFormItem } from './interface';
import { observable, action } from 'mobx';
import moment from 'moment';
import { Support } from '@mtt-nails/apis/dist/v3';
type ItemInput<T> = { value: T; validate?(): boolean; validator?: any[] };

// interface Validator {
//   //input ????? [] {

//   //}
//   name: 'REquire|LEnght',
//   input: string;

// }

// class REQuire {
//   name: 'REquire'
//   input: string;
//   check : () => Input.lenght !==0 ,
//   message: 'is ',
//   field://
// }

// class LEngth {
//   name: 'REquire'
//   input: string;
//   check : () => asdasd,
//   message: 'is ',
//   field://
// }

// constructor({ value, validate, validator = [] }: ItemInput<T>) {
//   this.defaultValue = this.value = value;
//   this.setting = { validate };
// }

export class Item<T> implements IFormItem<T> {
  @observable
  value: T;
  defaultValue: T;

  setting: any;

  constructor({ value, validate }: ItemInput<T>) {
    this.defaultValue = this.value = value;
    this.setting = { validate };
  }

  @action
  setValue = (value: T) => (this.value = value);

  isValid = () => {
    return this.setting.validate ? this.setting.validate(this.value) : true;
  };

  isDirty = () => {
    return this.value !== this.defaultValue;
  };

  onReset = () => {
    this.value = this.defaultValue;
  };

  onSetDefault = () => {
    this.defaultValue = this.value;
  };
}

export class OffDatesList implements IFormItem<Set<string>> {
  @observable
  value: Set<string>;
  defaultValue: Set<string>;

  setting: any;

  constructor({ value, validate }: { value: string[]; validate?(): boolean }) {
    this.defaultValue = this.value = this.initInDefault(value);
    this.setting = { validate };
  }

  initInDefault(value: string[]): Set<string> {
    const set: Set<string> = new Set();
    value.forEach(item => set.add(this.format(item as string)));
    return set;
  }

  convertToDate(): Date[] {
    const result: Date[] = [];
    this.value.forEach(value => result.push(new Date(value)));
    return result;
  }

  format(dateString: string) {
    const result = moment(new Date(dateString)).format('YYYY-MM-DD');
    return result;
  }

  isValid = () => (this.setting.validate ? this.setting.validate(this.value) : true);

  isDirty = (): boolean => {
    let check = false;
    if (this.value.size === this.defaultValue.size) {
      this.value.forEach(value => {
        if (this.defaultValue.has(value) === false) {
          check = true;
        }
      });
      return check;
    }
    return true;
  };

  @action
  addDate(date: Date) {
    this.value.add(this.format(date.toDateString()));
  }

  @action
  removeDate(date: Date) {
    this.value.delete(this.format(date.toDateString()));
  }

  @action
  setValue = () => {};

  getValueAsArray() {
    const array: string[] = [];
    this.value.forEach(value =>
      array.push(
        moment(value)
          .utc()
          .format(),
      ),
    );
    return array;
  }

  onReset = () => {
    this.value = new Set(this.defaultValue);
  };

  onSetDefault = () => {
    this.defaultValue = new Set(this.value);
  };
}

export class SpecialWorkDay implements IFormItem<Support.SpecialWorkDay[]> {
  @observable
  value: Support.SpecialWorkDay[];
  @observable
  defaultValue: Support.SpecialWorkDay[];

  constructor({ value }: { value: Support.SpecialWorkDay[] }) {
    this.value = Support.SpecialWorkDay.fromArray(value || []);
    this.defaultValue = Support.SpecialWorkDay.fromArray(value || []);
  }

  @action
  setValue = (value: Support.SpecialWorkDay[]) => {
    const [data] = value;
    const idx = this.value.findIndex(date => moment(date.from).isSame(moment(data.from)));
    const clone = [...this.value];
    if (idx >= 0) {
      clone.splice(idx, 1);
    } else {
      clone.push(data);
    }
    this.value = clone;
  };
  isValid = () => {
    return false;
  };
  isDirty = () => {
    if (this.value.length === this.defaultValue.length) {
      return this.value.some(selected => {
        return !this.defaultValue.some(date => moment(date.from).isSame(moment(selected.from)));
      });
    }
    return true;
  };
  @action
  onReset = () => {
    this.value = [...this.defaultValue];
  };
  @action
  onSetDefault = () => {
    this.defaultValue = [...this.value];
  };
}

export class Form implements IForm<any> {
  items: {
    [key: string]: IFormItem<any>;
  } = {};

  isValid = () => {
    return Object.values(this.items).some(item => item.isValid());
  };

  isDirty = () => Object.values(this.items).some(item => item.isDirty());

  onReset = () => {
    Object.keys(this.items).forEach(key => {
      this.items[key].onReset();
    });
  };

  onSetDefault = () => {
    Object.keys(this.items).forEach(key => {
      this.items[key].onSetDefault();
    });
  };

  getFormData = () => {
    const formData: any = {};
    Object.keys(this.items).forEach(key => {
      formData[key as string] = this.items[key].value;
    });
    return formData;
  };
}
