import { Button, createStyles, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';
import { reaction, runInAction } from 'mobx';
import { observer, Observer, useLocalStore } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '../../../../../components';
import DataTable, { ActionType } from '../../../../../components/Table/Store';
import { colors } from '../../../../../themes/theme';
import { AddRelation, RoleSelect } from '../../../components';
import Store from '../../Store';
import relationTableConfig from './tableConfig';

interface Props {
  userId: string;
  store: Store;
}

const ShopRelation: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const classes = styles();

  //TODO - Update Table Row With Other Ways
  reaction(
    () => props.store.isUpdateUI,
    async value => {
      if (value) {
        dataTable.fetchRemote(() => supportApi.getListShopRelation(props.userId));
        runInAction(() => {
          props.store.isUpdateUI = false;
        });
      }
    },
  );

  const dataTable: DataTable<Support.ShopUserRelation> = useLocalStore(
    () =>
      new DataTable({
        ...relationTableConfig,
        actionsCol: {
          label: t('Actions'),
          actions: [
            {
              name: ActionType.EDIT,
              onClick: (row: Support.ShopUserRelation) => {
                runInAction(() => {
                  props.store.roleSelectStore.isOpen = true;
                  props.store.roleSelectStore.value = row.relation;
                  props.store.relationId = row.id;
                });
              },
            },
            {
              name: ActionType.DELETE,
              onClick: async (row: Support.ShopUserRelation) => {
                await props.store.removeShopRelation(row.id);
              },
            },
          ],
        },
      }),
  );

  useEffect(() => {
    dataTable.fetchRemote(() => supportApi.getListShopRelation(props.userId));
  }, [dataTable, props.userId]);

  const handleClick = () => {
    runInAction(() => {
      props.store.addRelationStore.isOpen = true;
      props.store.addRelationStore.title = 'Create Relations With Shops';
    });
  };

  return (
    <Grid className={classes.margin}>
      <Grid item xs={12}>
        <Table data={dataTable} />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" className={classes.button} onClick={handleClick}>
          <Typography className={classes.text}>{t('Create')}</Typography>
        </Button>
      </Grid>
      <Observer>
        {() => (
          <RoleSelect
            relationId={props.store.relationId}
            store={props.store.roleSelectStore}
            onSubmit={props.store.updateRelation}
            onClose={props.store.closeRoleSelect}
          />
        )}
      </Observer>

      <Observer>
        {() => (
          <AddRelation
            userId={props.userId}
            store={props.store.addRelationStore}
            onSubmit={props.store.createRelation}
            onClose={props.store.closeAddRelation}
          />
        )}
      </Observer>
    </Grid>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    container: {
      paddingTop: 15,
    },
    button: {
      marginRight: theme.spacing(1),
      marginTop: 10,
    },
    text: {
      color: colors.white,
    },
  }),
);

export default observer(ShopRelation);
