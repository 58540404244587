import moment from 'moment';
import { vi, de, enUS } from 'date-fns/locale';

import { SortType } from '../components/Table/Store';
import i18n from '../i18n';

export const Sortable = {
  sortByString: (val1: string, val2: string, order: SortType): number => {
    if (order === SortType.ASC) {
      if (val1 < val2) return -1;
      if (val1 > val2) return 1;
    } else {
      if (val1 < val2) return 1;
      if (val1 > val2) return -1;
    }
    return 0;
  },
  sortByNumber: (val1: number, val2: number, order: SortType): number => {
    return order === SortType.ASC ? val1 - val2 : val2 - val1;
  },
  sortByDate: (val1: Date, val2: Date, order: SortType): number => {
    const measure1 = moment(val1).valueOf();
    const measure2 = moment(val2).valueOf();
    return order === SortType.ASC ? measure1 - measure2 : measure2 - measure1;
  },
};

export const caculateWeekOfMonth = (date: Date) => {
  return (
    moment(date)
      .utc()
      .week() -
    moment(date)
      .utc()
      .startOf('month')
      .week() +
    1
  );
};

export const strToLocale = (language: string) => {
  switch (language) {
    case 'vi': {
      return vi;
    }
    case 'de': {
      return de;
    }
    default: {
      return enUS;
    }
  }
};

export const translate = (key: string, option?: object) => {
  return key ? i18n.t(key, option) : '';
};

export default {
  Sortable,
  caculateWeekOfMonth,
  translate,
};
