/* eslint-disable no-use-before-define */
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  Typography,
  TableSortLabel,
  Select,
  InputBase,
  MenuItem,
  TextField,
  Button,
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import ActionIcon from './components/ActionIcon';
import DataTable, { SortType } from './Store';

interface Props {
  data: DataTable<any>;
  onDoubleClickRow?: (row: any) => void;
}

function GenericTable<T>(props: Props) {
  const { t } = useTranslation();

  const { data } = props;
  const emptyRows = data.paging.rowsPerPage - data.filteredItems.length;
  const classes = useStyles();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    props.data.setPage(page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.data.setRowsPerPage(Number(event.target.value));
  };

  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>, column: any) => {
    props.data.handleChangeSearchValue(column.searchKey ? column.searchKey : column.key, event.target.value);
  };

  return (
    <>
      <Grid item>
        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.box}>
          <Grid item>
            {data.displayName && (
              <Typography variant="h6" className={classes.text}>
                {t(data.displayName)}
              </Typography>
            )}

            {!data.displayName && <div className={classes.topLeftNode}>{data.leftTopTable}</div>}
          </Grid>

          <Grid item>
            {data.filterable && (
              <Select
                input={<InputBase />}
                className={classes.selectShop}
                value={data.filterable.selectedValue}
                // onChange={handleChangeFilter}
                displayEmpty>
                {data.filterable.items.map(item => (
                  <MenuItem value={item.value} key={item.value}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </Select>
            )}
            {!data.filterable && <div className={classes.topRightNode}>{data.rightTopTable}</div>}
          </Grid>
        </Grid>
      </Grid>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {data.columns.map(column => {
              return (
                <TableCell key={column.key}>
                  {!!column.searchable && (
                    <TextField
                      value={column.defaultSearchValue}
                      label={t(column.header)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchValueChange(e, column)}></TextField>
                  )}
                </TableCell>
                // t * 2,
                // padding: theme.spacing.unit / 2
              );
            })}

            <TableCell>
              <Button onClick={data.getData}>{t('Search')}</Button>
            </TableCell>
          </TableRow>

          <TableRow>
            {data.columns.map((column, index) => {
              const { order, orderBy } = data.sort;
              return (
                <TableCell
                  key={column.key}
                  sortDirection={orderBy === column.key ? order : false}
                  className={index === 0 ? classes.firstLabel : classes.label}>
                  {!!column.sort && (
                    <TableSortLabel
                      active={orderBy === column.key}
                      direction={order}
                      onClick={() => {
                        data.setSort(column.key, order === SortType.ASC ? SortType.DESC : SortType.ASC);
                      }}>
                      {t(column.header)}
                    </TableSortLabel>
                  )}
                </TableCell>
              );
            })}
            {!!data.actionsCol && <TableCell className={classes.lastLabel}>{t(data.actionsCol.label)}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.filteredItems.map(row => {
            return (
              <TableRow
                hover
                key={`${row.id}`} // Temp key, todo update
                onDoubleClick={() => props.onDoubleClickRow && props.onDoubleClickRow(row)}>
                {data.columns.map((column, index) => {
                  return (
                    <TableCell className={index === 0 ? classes.firstBodyCell : classes.bodyCell} key={column.key}>
                      {column.valueGetter(row, data.items)}
                    </TableCell>
                  );
                })}

                {!!data.actionsCol && (
                  <TableCell className={classes.bodyCell}>
                    {data.actionsCol.actions.map(action => {
                      return <ActionIcon key={action.name} name={action.name} onClick={() => action.onClick(row)} />;
                    })}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 57 * emptyRows }}>
              <TableCell colSpan={data.columns.length + 1} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelDisplayedRows={({ from, to, count }) => `${from} - ${to} / ${count}`}
              labelRowsPerPage={t('Number of row / page')}
              rowsPerPageOptions={[5, 10, 15]}
              count={data.totalRecord}
              rowsPerPage={data.paging.rowsPerPage}
              page={data.paging.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.background.default,
    },
    topLeftNode: {
      paddingTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
    },
    topRightNode: {
      paddingTop: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
    },
    box: {
      height: theme.spacing(7),
      backgroundColor: lighten(theme.palette.primary.light, 0.4),
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    text: {
      paddingLeft: theme.spacing(4),
      fontWeight: 'bold',
    },
    selectShop: {
      backgroundColor: theme.palette.background.default,
      paddingLeft: theme.spacing(2),
      marginRight: theme.spacing(4),
    },
    firstLabel: {
      fontWeight: 'bold',
      fontSize: theme.spacing(2),
      padding: theme.spacing(0.5),
      paddingLeft: 20,
    },
    label: {
      fontWeight: 'bold',
      fontSize: theme.spacing(2),
      padding: theme.spacing(0.5),
    },
    lastLabel: {
      fontWeight: 'bold',
      fontSize: theme.spacing(2),
      padding: theme.spacing(0.5),
      '&:last-child': {
        paddingRight: 0,
      },
    },
    firstBodyCell: {
      padding: theme.spacing(0.5),
      paddingLeft: 20,
    },
    bodyCell: {
      padding: theme.spacing(0.5),
    },
    lastBodyCell: {
      padding: theme.spacing(0.5),
      '&:last-child': {
        paddingRight: 0,
      },
    },
  }),
);

export default observer(GenericTable);
