import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { InputAdornment, TextField, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  value: string;
  label: string;
  name?: string;
  hasEye?: boolean;
  passwordVisible?: boolean;
  togglePassword?: () => void;
  onChange: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    margin: theme.spacing(),
    width: 200,
  },
}));

const PasswordInput: React.FC<Props> = ({ value, name, label, hasEye, passwordVisible, togglePassword, onChange }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(!!passwordVisible);

  React.useEffect(() => {
    setShowPassword(!!passwordVisible);
  }, [passwordVisible]);

  const toggleShowPassword = () => {
    if (togglePassword) {
      togglePassword();
    } else {
      setShowPassword(show => !show);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <TextField
      label={label}
      className={classes.input}
      type={showPassword ? 'text' : 'password'}
      value={value}
      name={name}
      onChange={handleChange}
      variant="outlined"
      InputProps={{
        endAdornment: hasEye && (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
