import { TimePicker } from '@material-ui/pickers';
import React, { useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FormInput } from '../type';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    margin: theme.spacing(),
  },
}));

function SelectTime({ value, setValue, label, editable }: FormInput<Date>) {
  const classes = useStyles();

  const handleChange = useCallback(
    (value: Date | null) => {
      setValue(value || new Date());
    },
    [setValue],
  );

  return (
    <TimePicker
      className={classes.input}
      label={label}
      inputVariant="outlined"
      disabled={!editable}
      value={value}
      onChange={handleChange}
    />
  );
}

export default SelectTime;
