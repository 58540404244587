import { BaseStore } from '../cores';
import { observable, runInAction } from 'mobx';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';

import { IAsyncTracking } from './AsyncTracking';

export default class extends BaseStore implements IAsyncTracking {
  @observable shops: Support.ShopBase[] = [];

  load = () => this.fetch();

  fetch = async () => {
    const { data } = await supportApi.getShops();
    runInAction(() => {
      this.shops = data;
    });
  };
}
