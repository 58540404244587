import React, { useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { Support } from '@mtt-nails/apis/dist/v3';

import { DateTime } from '../../../../components';

const useStyles = makeStyles((theme: Theme) => ({
  to: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  btnDelete: {
    marginLeft: theme.spacing(2),
  },
}));

interface IProps {
  from: Date | null;
  to: Date | null;
  disabled?: boolean;
  fromChange?: (date: Date | null) => void;
  toChange?: (date: Date | null) => void;
  onCancel?: () => void;
  onDeleteSpecialDay?: (date: Support.SpecialWorkDay[]) => void;
}

const DateRow: React.FC<IProps> = ({
  from,
  to,
  disabled,
  fromChange,
  toChange,
  onCancel = noop,
  onDeleteSpecialDay = noop,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleDelete = useCallback(() => {
    if (from && to) onDeleteSpecialDay([{ from, to }]);
  }, [from, to, onDeleteSpecialDay]);

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" mb={2}>
      <DateTime label={t('From')} date={from} onChange={fromChange} disabled={disabled} />
      <span className={classes.to}>{t('-')}</span>
      <DateTime label={t('To')} date={to} onChange={toChange} disabled={disabled} minDate={from || undefined} />
      {disabled && (
        <Button color="secondary" className={classes.btnDelete} variant="outlined" onClick={handleDelete}>
          {t('Delete')}
        </Button>
      )}
      {!disabled && (from || to) && (
        <Button color="secondary" className={classes.btnDelete} variant="outlined" onClick={onCancel}>
          {t('Cancel')}
        </Button>
      )}
    </Box>
  );
};

export default DateRow;
