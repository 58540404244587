import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const Spinner = () => {
  return (
    <Box justifyContent="center" alignItems="center" flex={1} display={'flex'}>
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
