import React from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchInput } from '../Items';
import { Form } from '../type';

function SwitchForm({ store, editable }: Form<boolean>) {
  const { t } = useTranslation();

  return <SwitchInput value={store.value} label={t(store.label)} setValue={store.setValue} editable={editable} />;
}

export default SwitchForm;
