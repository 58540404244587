import { observable, action, runInAction } from 'mobx';
import { supportApi, Support } from '@mtt-nails/apis/dist/v3';
import { BaseStore } from '../../../cores';
import { ModalStore } from '../../../components/Modal';
import i18n from '../../../i18n';
import UserStore from './UserStore';
import { AddRelationStore, RoleSelectStore } from '../components';

class Store extends BaseStore {
  @observable
  profile: Support.Profile = Support.Profile.from({ permissions: [] });

  @observable
  modalStore: ModalStore = new ModalStore();

  @observable
  roleSelectStore: RoleSelectStore = new RoleSelectStore();

  @observable
  addRelationStore: AddRelationStore = new AddRelationStore();

  @observable
  userStore: UserStore = new UserStore(this.profile);

  @observable
  relationId: string;

  @observable
  isUpdateUI = false;

  constructor(userId: string) {
    super();
    this.fetch(userId);
  }

  @action
  fetch = async (userId: string) => {
    try {
      const { data } = await supportApi.getProfileById(userId);
      runInAction(() => {
        this.profile = data;
        this.userStore = new UserStore(Support.Profile.from(data));
      });
    } catch (error) {
      this.debug(error);
    }
  };

  @action
  submitForm = async () => {
    try {
      await this.userStore.save();
    } catch (error) {
      this.setError(error);
    }
  };

  @action
  submitPassword = async (password: string) => {
    try {
      const data = {
        userId: this.userStore.userId,
        new_password: password,
      };
      await supportApi.updatePassword(data);
      this.setSuccess(i18n.t('Success'));
    } catch (err) {
      this.setError(err);
    }
  };

  @action
  createRelation = async (shopId: string, userId: string, role: string) => {
    try {
      await supportApi.addRelation({ shopId, userId, role });
      runInAction(() => {
        this.setSuccess(i18n.t('Success'));
        this.addRelationStore.isOpen = false;
        this.isUpdateUI = true;
      });
    } catch (err) {
      runInAction(() => {
        this.setError(err);
        this.debug(err);
      });
    }
  };

  @action
  updateRelation = async (relationId: string, relationName: string) => {
    try {
      await supportApi.updateShopUserRelation({ relationId, relationName });
      runInAction(() => {
        this.setSuccess(i18n.t('Success'));
        this.roleSelectStore.isOpen = false;
        this.isUpdateUI = true;
      });
    } catch (err) {
      runInAction(() => {
        this.setError(err);
      });
    }
  };

  @action
  removeShopRelation = async (id: string) => {
    try {
      await supportApi.deleteShopUserRelation(id);
      runInAction(() => {
        this.setSuccess(i18n.t('Success'));
        this.isUpdateUI = true;
      });
    } catch (err) {
      runInAction(() => {
        this.setError(err);
        this.debug(err);
      });
    }
  };

  @action
  closeRoleSelect = () => {
    this.roleSelectStore.isOpen = false;
  };

  @action
  closeAddRelation = () => {
    this.addRelationStore.isOpen = false;
  };

  @action
  closeModal = () => {
    this.modalStore.isModalOpen = false;
  };
}

export default Store;
