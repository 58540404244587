/* eslint-disable no-use-before-define */
import * as React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { WithStyles, withStyles } from '@material-ui/core';
import PrivateAppBar from './PrivateAppBar';
import Drawer from './Drawer';
import Store from './Store';
import styles from './styles';

interface Props extends WithStyles<typeof styles> {}
@observer
class PrivateLayout extends React.Component<Props> {
  store = new Store();

  handleDrawerOpen = () => {
    this.store.setOpen(true);
  };

  handleDrawerClose = () => {
    this.store.setOpen(false);
  };

  render() {
    const { classes } = this.props;
    const {
      open,
      anchorEl,
      mobileMoreAnchorElToggle,
      isToggle,
      setClosed,
      setToggle,
      setToggleMobile,
      setClosedMobile,
    } = this.store;
    return (
      <>
        <PrivateAppBar
          open={open}
          anchorEl={anchorEl}
          mobileMoreAnchorElToggle={mobileMoreAnchorElToggle}
          handleDrawerOpen={this.handleDrawerOpen}
          setToggle={setToggle}
          isToggle={isToggle}
          setClosed={setClosed}
          setClosedMobile={setClosedMobile}
          setToggleMobile={setToggleMobile}
        />
        <Drawer open={open} handleDrawerClose={this.handleDrawerClose} />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: open,
          })}>
          {this.props.children}
        </main>
      </>
    );
  }
}

export default withStyles(styles)(PrivateLayout);
