import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { FormInput } from '../type';

interface Props extends FormInput<string> {
  dialingCode: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 195,
    margin: theme.spacing(),
  },
}));

function PhoneInput({ value, editable, label, dialingCode, setValue }: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const classes = useStyles();
  return (
    <TextField
      className={classes.input}
      label={label}
      InputProps={{
        startAdornment: <InputAdornment position="start">{dialingCode || ''}</InputAdornment>,
      }}
      disabled={!editable}
      variant="outlined"
      value={value}
      onChange={handleChange}
    />
  );
}

export default PhoneInput;
