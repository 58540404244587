import { Button, createStyles, Grid, makeStyles, Theme, Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordInput } from '../../../../../components';

interface Props {
  onSubmit: (password: string) => void;
}

const ChangePassword: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const classes = styles();

  const [password, setPassword] = useState({ newPassword: '', confirmPassword: '' });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [disable, setDisable] = useState(password.newPassword !== password.confirmPassword || !password.newPassword);

  React.useEffect(() => {
    setDisable(password.newPassword !== password.confirmPassword || !password.newPassword);
  }, [password]);

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChangePassword = (key: string) => (value: string) => {
    setPassword(current => ({
      ...current,
      [key]: value,
    }));
  };

  const handleReset = () => {
    setPassword({ newPassword: '', confirmPassword: '' });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(password.newPassword);
    handleReset();
  };

  return (
    <Box>
      <Grid container>
        <Grid item>
          <PasswordInput
            value={password.newPassword}
            onChange={handleChangePassword('newPassword')}
            label={t('New password')}
            hasEye
            passwordVisible={passwordVisible}
            togglePassword={togglePassword}
          />
        </Grid>
        <Grid item>
          <PasswordInput
            value={password.confirmPassword}
            onChange={handleChangePassword('confirmPassword')}
            label={t('Re-type new password')}
            passwordVisible={passwordVisible}
          />
        </Grid>
      </Grid>
      <Box m={1}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={disable}
          onClick={handleSubmit}>
          {t('SAVE')}
        </Button>
        <Button color="secondary" variant="contained" className={classes.button} onClick={handleReset}>
          {t('RESET')}
        </Button>
      </Box>
    </Box>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    container: {
      paddingTop: 15,
    },
    button: {
      marginRight: theme.spacing(1),
      minWidth: theme.spacing(15),
    },
    input: {
      margin: theme.spacing(),
      width: 195,
    },
  }),
);

export default observer(ChangePassword);
