import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, Observer } from 'mobx-react';
import { observable, computed, action } from 'mobx';
import { Grid } from '@material-ui/core';
import { Item, Form } from '../helper';
import { IFormItem } from '../interface';
import Input from '../Items/Input';

interface NameType {
  firstName: string;
  lastName: string;
}

const initial: NameType = {
  firstName: '',
  lastName: '',
};

export class Name implements IFormItem<NameType> {
  @observable
  form: Form = new Form();

  constructor(name: NameType = initial) {
    this.form.items['firstName'] = new Item({ value: name.firstName });
    this.form.items['lastName'] = new Item({ value: name.lastName });
  }

  @computed
  get value() {
    return {
      firstName: this.form.items['firstName'].value,
      lastName: this.form.items['lastName'].value,
    };
  }

  setValue = () => {};

  isDirty = () => this.form.isDirty();

  isValid = () => this.form.isValid();

  @action
  onReset = () => {
    this.form.onReset();
  };

  @action
  onSetDefault = () => {
    this.form.onSetDefault();
  };
}

interface Props {
  data: Name;
}

const UserNameControl: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <Grid item container xs={12} spacing={4}>
      <Observer>{() => <Input label={t('Firstname')} data={data.form.items.firstName} />}</Observer>
      <Observer>{() => <Input label={t('Lastname')} data={data.form.items.lastName} />}</Observer>
    </Grid>
  );
};

export default observer(UserNameControl);
