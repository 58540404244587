import React from 'react';
import { observer, Observer } from 'mobx-react';
import { Grid, makeStyles, Theme, createStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Store from '../../Store';
import { ReportPanel, FormComponents } from '../../../../components';
import { Password } from '../../../../components/FormComponents/Groups/ChangePasswordControl';

interface Props {
  store: Store;
}

const ChangePassword: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const classes = styles();
  return (
    <Grid className={classes.margin}>
      <ReportPanel title={t('Change password')}>
        <Grid container spacing={4} className={classes.container}>
          <Observer>
            {() => (
              <FormComponents.Groups.ChangePasswordControl
                data={props.store.passwordStore.form.items.password as Password}
              />
            )}
          </Observer>
          <Grid item xs={12}>
            <Observer>
              {() => (
                <>
                  <Button
                    variant="contained"
                    disabled={!props.store.passwordStore.isEditable}
                    onClick={props.store.submitPassword}
                    className={classes.button}>
                    {t('SAVE')}
                  </Button>
                </>
              )}
            </Observer>
            <Button variant="contained" onClick={props.store.passwordStore.onCancel} className={classes.button}>
              {t('RESET')}
            </Button>
          </Grid>
        </Grid>
      </ReportPanel>
    </Grid>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    container: {
      paddingTop: 15,
    },
    button: {
      marginRight: theme.spacing(1),
    },
  }),
);

export default observer(ChangePassword);
