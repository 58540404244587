import React, { Component } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider, observer } from 'mobx-react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Authentication, Guard, Preloader } from './containers';
import { privateRoutes, publishRoutes } from './screens/routes';
import i18n from './i18n';
import * as themes from './themes';
import { createRootStore } from './stores';
import 'react-day-picker/lib/style.css';
import 'react-flags-select/css/react-flags-select.css';
import './index.css';
import { strToLocale } from './cores/utils';

const store = createRootStore();

@observer
class App extends Component {
  componentDidMount() {
    store.loadDeps();
  }

  render() {
    return (
      <Provider {...store.rootStore}>
        <Preloader loading={!store.isReady}>
          <I18nextProvider i18n={i18n}>
            <MuiThemeProvider theme={themes['default']}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={strToLocale(i18n.language)}>
                <Router>
                  <React.Fragment>
                    <Authentication {...store.rootStore} routes={publishRoutes} />
                    <Guard {...store.rootStore} routes={privateRoutes} />
                  </React.Fragment>
                </Router>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </I18nextProvider>
        </Preloader>
      </Provider>
    );
  }
}

export default App;
