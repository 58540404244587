import { TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormInput } from '../type';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    margin: theme.spacing(),
  },
}));

interface Props<T> extends FormInput<T> {
  editable: boolean;
}

function DefaultInput<T>({ label, setValue, value, editable, type }: Props<T>) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value as any);
  };

  return (
    <TextField
      label={t(label)}
      disabled={!editable}
      value={value}
      onChange={handleChange}
      className={classes.input}
      variant="outlined"
      type={type}
    />
  );
}

export default observer(DefaultInput);
