import React from 'react';
import { Home as HomeIcon, GroupAdd as ShareOwnerIcon, Settings as SettingsIcon } from '@material-ui/icons';
import AppMap from '../../screens/AppMap';

export interface DrawerItem {
  icon: React.ReactElement<any>;
  name: string;
  path: string;
  roles?: string[];
}

const drawerItems: DrawerItem[] = [
  {
    icon: <HomeIcon />,
    name: 'Home',
    path: AppMap.Dashboard,
  },
  {
    icon: <SettingsIcon />,
    name: 'Shop Management',
    path: AppMap.ShopManagement,
    roles: ['supporter'],
  },
  {
    icon: <ShareOwnerIcon />,
    name: 'User Management',
    path: AppMap.UserManagement,
    roles: ['supporter'],
  },
  {
    icon: <ShareOwnerIcon />,
    name: 'Staff Management',
    path: AppMap.StaffManagement,
    roles: ['supporter'],
  },
];
export default drawerItems;
