import theme from './theme';
import { createMuiTheme, Theme } from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

const mergeOptions = (options: ThemeOptions): Theme => {
  return createMuiTheme({
    ...options,
  });
};

export default mergeOptions(theme);
