import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FormInput } from '../type';

interface Props<T> extends FormInput<T> {
  options: {
    label: string;
    value: T;
  }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 195,
    margin: theme.spacing(),
  },
}));

function SelectInput<T>({ label, value, editable, options, setValue }: Props<T>) {
  const classes = useStyles();

  const handleSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
    setValue(e.target.value as T);
  };

  return (
    <FormControl variant="outlined" className={classes.input}>
      <InputLabel id="mui-select">{label}</InputLabel>
      <Select labelId="mui-select" disabled={!editable} onChange={handleSelect} value={value} label={label}>
        {options.map(option => (
          <MenuItem value={`${option.value}`} key={`${option.value}`}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectInput;
