import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateInput } from '../Items';
import { Form } from '../type';

function DateForm({ store, editable }: Form<Date | null>) {
  const { t } = useTranslation();
  return <DateInput editable={editable} value={store.value} setValue={store.setValue} label={t('Birthday')} />;
}

export default DateForm;
