import { createStyles, Grid, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { CountryCode } from 'libphonenumber-js';
import { observer, Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';

import { FormInput } from '../type';

function SelectCountryBox({ setValue, value, editable, label }: FormInput<string>) {
  const flagsSelectRef: React.RefObject<any> = React.useRef();
  const { t } = useTranslation();

  const handleChangeCountryCode = (countryCode: CountryCode) => {
    setValue(countryCode);
  };

  const classes = styles();

  useEffect(() => {
    flagsSelectRef.current.updateSelected(value as CountryCode);
  }, [value]);

  return (
    <Grid item>
      <TextField
        label={label}
        value={value}
        variant="outlined"
        className={classes.addressInput}
        disabled={!editable}
        InputProps={{
          inputProps: {
            className: classes.input,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Observer>
                {() => (
                  <ReactFlagsSelect
                    searchable
                    blackList
                    showSelectedLabel={false}
                    disabled={!editable}
                    onSelect={handleChangeCountryCode}
                    defaultCountry={value}
                    ref={flagsSelectRef}
                    countries={['AS', 'AG']}
                    className={!value ? classes.inputNoFlag : classes.flagBox}
                    placeholder={t('Select country')}
                  />
                )}
              </Observer>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginLeft: -50,
    },
    inputNoFlag: {
      marginRight: theme.spacing(1),
      width: 200,
      outline: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      caretColor: 'transparent',
    },
    flagBox: {
      width: 110,
    },
    addressInput: {
      width: 195,
      outline: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      caretColor: 'transparent',
      margin: theme.spacing(),
    },
  }),
);
export default observer(SelectCountryBox);
