/* eslint-disable no-use-before-define */
import React from 'react';
import { inject, observer, disposeOnUnmount, Observer } from 'mobx-react';
import { StoreNames, RootStore } from '../../stores';
import { computed, when } from 'mobx';
import { History } from 'history';
import Store from './Store';
import AppMap from '../AppMap';
import { withTranslation, WithTranslation } from 'react-i18next';
import logo from '../../assets/logo.png';
import { Grid, TextField, Button, Checkbox, WithStyles, withStyles, Link, FormControlLabel } from '@material-ui/core';
import styles from './styles';
import Background from './component/Background';
interface Props extends RootStore, WithTranslation, WithStyles<typeof styles> {
  history: History;
}

@inject(StoreNames.Authorization)
@observer
class Login extends React.Component<Props> {
  store = new Store();

  @computed
  get Authorization() {
    return this.props[StoreNames.Authorization];
  }

  @disposeOnUnmount
  onLoginSuccess = when(
    () => this.store.status,
    () => {
      this.props.history.replace({ pathname: AppMap.Dashboard });
      this.Authorization.authorize(this.store.authorization);
    },
  );

  rememberCheck = () => {
    this.store.rememberToggle();
  };

  setUsername = (e: React.ChangeEvent<HTMLInputElement>) => this.store.changeUsername(e.target.value);

  setPassword = (e: React.ChangeEvent<HTMLInputElement>) => this.store.changePassword(e.target.value);

  render() {
    const { t, classes } = this.props;

    return (
      <>
        <Background />
        <form
          onKeyPress={event => {
            if (event.key === 'Enter') {
              this.store.authenticate();
            }
          }}>
          <Grid container>
            <Grid item xs={12}>
              <img src={logo} alt="" className={classes.logo} />
            </Grid>
            <Grid item sm={6} md={4} lg={3} xl={2}>
              <Observer>
                {() => (
                  <TextField
                    autoFocus
                    fullWidth
                    label={t('Username')}
                    autoComplete="current-account"
                    margin="normal"
                    variant="outlined"
                    value={this.store.user.username}
                    onChange={this.setUsername}
                  />
                )}
              </Observer>
              <Observer>
                {() => (
                  <TextField
                    fullWidth
                    label={t('Password')}
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    variant="outlined"
                    value={this.store.user.password}
                    onChange={this.setPassword}
                  />
                )}
              </Observer>
              <Grid container justify="space-between" alignItems="center">
                <FormControlLabel
                  control={<Checkbox checked={this.store.rememberChecked} onChange={this.rememberCheck} />}
                  label={t('Remember me')}
                />
                <Link
                  component="a"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    alert("I'm a button.");
                  }}>
                  {t('Forgot password?')}
                </Link>
              </Grid>
              <Button fullWidth variant="contained" onClick={this.store.authenticate} size="large">
                {t('LOGIN')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(Login));
