import React, { Component } from 'react';
import { Redirect, RouteProps, withRouter, RouteComponentProps, Route, matchPath } from 'react-router-dom';
import isNull from 'lodash/isNull';
import { observer } from 'mobx-react';
import { StoreNames, RootStore } from '../stores';
import AppMap from '../screens/AppMap';
import PrivateLayout from './PrivateLayout';
import { Error } from '../components';
import i18n from '../i18n';
import { Button } from '@material-ui/core';

interface Props extends RouteComponentProps, RootStore {
  routes: RouteProps[];
}

@observer
export class Guard extends Component<Props> {
  logout = () => {
    this.props[StoreNames.Authorization].deauthorize();
  };

  canAccess() {
    return this.props[StoreNames.Authorization].isAuthenticated;
  }

  isPrivateRoute() {
    const { routes, location } = this.props;
    return routes.some(route => !isNull(matchPath(location.pathname, route)));
  }

  isSupporter() {
    return this.props[StoreNames.User].userRoles.includes('supporter');
  }

  render() {
    if (this.canAccess() && this.isPrivateRoute()) {
      if (!this.isSupporter()) {
        return (
          <>
            <Error errorCode={403} errorTitle={i18n.t('Forbidden')} errorMessage={i18n.t("You don't have permission!")}>
              <Button onClick={this.logout}>{i18n.t('Back')}</Button>
            </Error>
          </>
        );
      }
      return (
        <PrivateLayout>
          {this.props.routes.map(route => (
            <Route key={`${route.path}`} {...route} />
          ))}
        </PrivateLayout>
      );
    }

    if (this.isPrivateRoute()) {
      return (
        <Redirect
          to={{
            pathname: AppMap.Login,
            state: { from: this.props.location },
          }}
        />
      );
    }

    return null;
  }
}

export default withRouter(Guard);
