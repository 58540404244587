import React from 'react';
import { observer, Observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Grid, Chip, Popover, Typography, createStyles, makeStyles } from '@material-ui/core';
import DayPicker, { DayModifiers } from 'react-day-picker';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';

import 'moment/locale/vi';
import 'moment/locale/de';

interface Props {
  open: boolean;
  selectedDays: Date[];
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onDayClick: (day: Date, modifiers: DayModifiers, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDelete: (day: Date) => void;
}

const PopoverCalendar: React.FC<Props> = (props: Props) => {
  const { open, anchorEl, onClose, selectedDays, onDayClick } = props;
  const classes = styles();
  const { t } = useTranslation();

  const handleDelete = (days: Date[], index: number) => {
    const chosenDay = days[index];
    props.onDelete(chosenDay);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      className={classes.popover}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <div className={classes.outerWrapper}>
        <Typography variant="h5" className={classes.title}>
          {t('Day-offs details')}
        </Typography>
      </div>
      <Grid container className={classes.wrapper}>
        <Grid item>
          <Observer>
            {() => (
              <DayPicker
                locale="vi"
                className={classes.picker}
                selectedDays={selectedDays.map(d => new Date(d))}
                onDayClick={onDayClick}
                localeUtils={MomentLocaleUtils}
              />
            )}
          </Observer>
        </Grid>
        <Grid item>
          <Observer>
            {() => (
              <div className={classes.scroll}>
                {selectedDays.map((day: Date, index: number) => {
                  return (
                    <Chip
                      color="primary"
                      key={index}
                      className={classes.calendarChip}
                      label={`${moment(day).format('DD MMM YYYY')}`}
                      onDelete={() => handleDelete(selectedDays, index)}
                    />
                  );
                })}
              </div>
            )}
          </Observer>
        </Grid>
      </Grid>
    </Popover>
  );
};

const colors = {
  purple: '#3f51b5',
  white: 'white',
};
// {TODO cover style on the themes}
const styles = makeStyles(() =>
  createStyles({
    popover: {
      padding: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      color: colors.white,
    },
    wrapper: {
      backgroundColor: colors.white,
    },
    outerWrapper: {
      backgroundColor: colors.purple,
      padding: 20,
    },
    scroll: {
      maxHeight: 280,
      maxWidth: 300,
      overflow: 'auto',
      margin: 20,
    },
    picker: {
      maxHeight: 280,
      maxWidth: 300,
      margin: 20,
      outline: 'none',
    },
    calendarChip: {
      margin: 2,
      width: 120,
    },
  }),
);

export default observer(PopoverCalendar);
