import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { appbarStyles } from './styles';
import { withStyles, WithStyles, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Menu as MenuIcon, AccountCircle, MoreVert as MoreIcon } from '@material-ui/icons';
import ProfileMenu from './ProfileMenu';
import { StoreNames, RootStore } from '../../stores';
import { inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation, WithStyles<typeof appbarStyles>, RouteComponentProps {
  open: boolean;
  anchorEl: boolean;
  mobileMoreAnchorElToggle: boolean;
  handleDrawerOpen: any;
  setToggle: any;
  isToggle: boolean;
  setClosed: any;
  setToggleMobile: any;
  setClosedMobile: any;
}

interface InjectedProps extends Props, RootStore {}

@inject(StoreNames.Authorization)
@observer
class PrivateAppBar extends React.Component<Props> {
  anchorEl: HTMLElement;
  mobileMoreAnchorEl: HTMLElement;

  get injected() {
    return this.props as InjectedProps;
  }

  handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  setToggle = () => {
    this.props.setToggle();
  };

  setToggleMobile = () => {
    this.props.setToggleMobile();
  };

  setClosed = (event: React.ChangeEvent<{}>) => {
    if (this.anchorEl.contains(event.target as Node)) {
      return;
    }
    this.props.setClosed();
  };

  setClosedMobile = (event: React.ChangeEvent<{}>) => {
    if (this.mobileMoreAnchorEl.contains(event.target as Node)) {
      return;
    }
    this.props.setClosedMobile();
  };

  logout = () => {
    this.props.setClosed();
    this.injected[StoreNames.Authorization].deauthorize();
  };

  navigateProfile = () => {
    this.props.setClosed();
    this.props.history.push('/profile');
  };

  render() {
    const { classes, open, anchorEl, handleDrawerOpen, isToggle, mobileMoreAnchorElToggle } = this.props;
    const isMenuOpen = Boolean(anchorEl);

    return (
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar disableGutters={!open} variant="dense">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classNames(classes.menuButton, open && classes.hide)}>
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-owns={isMenuOpen ? 'material-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.setToggle}
              color="inherit"
              buttonRef={node => {
                this.anchorEl = node as HTMLElement;
              }}>
              <AccountCircle />
            </IconButton>
            <ProfileMenu
              open={isToggle}
              anchorEl={this.anchorEl}
              onClickAway={this.setClosed}
              logout={this.logout}
              navigateProfile={this.navigateProfile}
            />
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-haspopup="true"
              onClick={this.setToggleMobile}
              color="inherit"
              buttonRef={node => {
                this.mobileMoreAnchorEl = node as HTMLElement;
              }}>
              <ProfileMenu
                open={mobileMoreAnchorElToggle}
                anchorEl={this.mobileMoreAnchorEl}
                onClickAway={this.setClosedMobile}
                logout={this.logout}
                navigateProfile={this.navigateProfile}
              />
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(appbarStyles)(withTranslation()(withRouter(PrivateAppBar)));
