import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, Observer } from 'mobx-react';
import { observable, computed, action } from 'mobx';
import { Grid } from '@material-ui/core';
import { Item, Form } from '../helper';
import { IFormItem } from '../interface';
import PasswordInput from '../Items/PasswordInput';

interface PasswordType {
  currentPassword: string;
  newPassword: string;
  retypePassword: string;
}

const initial: PasswordType = {
  currentPassword: '',
  newPassword: '',
  retypePassword: '',
};

export class Password implements IFormItem<PasswordType> {
  @observable
  form: Form = new Form();

  constructor(password: PasswordType = initial) {
    this.form.items['currentPassword'] = new Item({ value: password.currentPassword });
    this.form.items['newPassword'] = new Item({ value: password.newPassword });
    this.form.items['retypePassword'] = new Item({ value: password.retypePassword });
  }

  @computed
  get value() {
    return {
      currentPassword: this.form.items['currentPassword'].value,
      newPassword: this.form.items['newPassword'].value,
      retypePassword: this.form.items['retypePassword'].value,
    };
  }

  setValue = () => {};

  isDirty = () => this.form.isDirty();

  isValid = () => this.form.isValid();

  @action
  onReset = () => {
    this.form.onReset();
  };

  @action
  onSetDefault = () => {
    this.form.onSetDefault();
  };
}

interface Props {
  data: Password;
  disabled?: boolean;
}

const ChangePasswordControl: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} spacing={4}>
      <Observer>
        {() => <PasswordInput label={t('Current password')} data={data.form.items.currentPassword} />}
      </Observer>
      <Observer>{() => <PasswordInput label={t('New password')} data={data.form.items.newPassword} />}</Observer>
      <Observer>
        {() => <PasswordInput label={t('Re-type new password')} data={data.form.items.retypePassword} />}
      </Observer>
    </Grid>
  );
};

export default observer(ChangePasswordControl);
