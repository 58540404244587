import { action, observable, runInAction } from 'mobx';
import { BaseStore } from '../../cores';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';

export default class extends BaseStore {
  authorization: Support.Authorization;

  @observable status = false;

  @observable rememberChecked = false;

  @observable user: { username: string; password: string } = {
    username: 'supporter@test.com',
    password: '123456',
  };

  @action
  authenticate = async () => {
    try {
      const response = await supportApi.authenticate({
        username: this.user.username,
        password: this.user.password,
      });
      runInAction(() => {
        this.authorization = response.data;
        this.status = true;
      });
    } catch (error) {
      this.setError(error);
    }
  };

  @action
  changeUsername = (username: string) => (this.user.username = username);

  @action
  changePassword = (password: string) => (this.user.password = password);

  @action
  rememberToggle = () => (this.rememberChecked = !this.rememberChecked);
}
