import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from 'material-ui-pickers';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IFormItem } from '../interface';

interface TimePickerProps {
  data: IFormItem<string>;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  input: {
    minWidth: 195,
  },
}));

const convert = (str: string, min = false) => {
  if (!min) {
    return +str.split(':')[0];
  } else {
    return +str.split(':')[1];
  }
};

const addZero = (i: any) => {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
};

const Time: React.FC<TimePickerProps> = (props: TimePickerProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid item>
      <TimePicker
        className={classes.input}
        label={t('Opened time')}
        cancelLabel={t('CANCEL')}
        okLabel={t('CHOOSE')}
        value={new Date().setHours(convert(props.data.value), convert(props.data.value, true))}
        onChange={(date: MaterialUiPickersDate) =>
          date && props.data.setValue(`${addZero(date.getHours())}:${addZero(date.getUTCMinutes())}`)
        }
        disabled={props.disabled}
        minutesStep={15}
        inputVariant={'outlined'}
      />
    </Grid>
  );
};

export default observer(Time);
