import { BaseStore } from '../../cores';
import { observable, action, runInAction } from 'mobx';
import { ModalStore } from '../../components/Modal';
import { supportApi, Support } from '@mtt-nails/apis/dist/v3';
import i18n from '../../i18n';
import UserStore from './UserStore';
import PasswordStore from './PasswordStore';

class Store extends BaseStore {
  @observable
  modalStore: ModalStore = new ModalStore();

  @observable
  userStore: UserStore = new UserStore();

  @observable
  passwordStore: PasswordStore = new PasswordStore();

  constructor() {
    super();
    this.fetch();
  }

  @action
  fetch = async () => {
    try {
      const { data } = await supportApi.getProfile();
      runInAction(() => {
        this.userStore = new UserStore(Support.Profile.from(data));
      });
    } catch (error) {
      this.debug(error);
    }
  };

  @action
  submitForm = async () => {
    try {
      //TODO - MESS DATA TYPE SEND TO SERVER "Cant use getFormData function"
      const data = {
        firstName: this.userStore.form.items.name.value.firstName,
        lastName: this.userStore.form.items.name.value.lastName,
        address: this.userStore.form.items.address.value,
        email: this.userStore.form.items.contact.value.email,
        phone: this.userStore.form.items.contact.value.phone,
      };
      await supportApi.updateProfile(data);
      runInAction(() => {
        this.userStore.reset();
        this.modalStore.isModalOpen = false;
        this.setSuccess(i18n.t('Success'));
      });
    } catch (err) {
      runInAction(() => {
        this.modalStore.isModalOpen = false;
        this.setError(err);
      });
    }
  };

  @action
  submitPassword = async () => {
    try {
      const data = {
        new_password: this.passwordStore.form.items.password.value.newPassword,
        current_password: this.passwordStore.form.items.password.value.currentPassword,
      };
      await supportApi.changePassword(data);
      runInAction(() => {
        this.passwordStore.reset();
        this.setSuccess(i18n.t('Success'));
      });
    } catch (err) {
      this.setError(err);
    }
  };

  @action
  onSaveUserInfomation = () => {
    this.modalStore.isModalOpen = true;
    this.modalStore.modalTitle = i18n.t('Change personal information');
    this.modalStore.modalMessage = i18n.t(
      'The information will be permanently changed. Are you sure to change the information ?',
    );
  };

  @action
  closeModal = () => {
    this.modalStore.isModalOpen = false;
  };
}

export default Store;
