import { utils } from '../../../cores';
import { TableConfig, SortType } from '../../../components/Table_pagination/Store';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';

const userTableConfig: TableConfig<Support.Profile> = {
  displayName: 'User list',
  items: [],
  columns: [
    {
      key: 'name',
      header: 'Fullname',
      searchable: true,
      valueGetter: (row: Support.Profile) => row.name,
      sort: (val1: Support.Profile, val2: Support.Profile, order: SortType) =>
        utils.Sortable.sortByString(val1.name, val2.name, order),
    },
    {
      key: 'id',
      header: 'ID',
      searchable: true,
      valueGetter: (row: Support.Profile) => `${row.id}`,
      sort: (val1: Support.Profile, val2: Support.Profile, order: SortType) =>
        utils.Sortable.sortByString(`${val1.id}`, `${val2.id}`, order),
    },
    {
      key: 'phone',
      header: 'Phone number',
      searchable: true,
      searchKey: 'phone.number',
      valueGetter: (row: Support.Profile) => `${row.phone.dialing_code} ${row.phone.number}`,
      sort: (val1: Support.Profile, val2: Support.Profile, order: SortType) =>
        utils.Sortable.sortByString(
          `${val1.phone.dialing_code}${val1.phone.number}`,
          `${val2.phone.dialing_code}${val2.phone.number}`,
          order,
        ),
    },
    {
      key: 'email',
      header: 'E-mail',
      searchable: true,
      searchKey: 'email',
      valueGetter: (row: Support.Profile) => row.email,
      sort: (val1: Support.Profile, val2: Support.Profile, order: SortType) =>
        utils.Sortable.sortByString(val1.email, val2.email, order),
    },
  ],
  fetcherData: supportApi.getUserQueryRequest,
};

export default userTableConfig;
