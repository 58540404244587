import React, { ReactNode } from 'react';
import { makeStyles, Grid, Container } from '@material-ui/core';
import { colors } from '../../themes/theme';

interface Props {
  errorCode: number;
  errorTitle: string;
  errorMessage: string;
  children?: ReactNode;
}

const Error: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { errorCode, errorTitle, errorMessage, children } = props;

  return (
    <>
      <Container className={classes.container}>
        <Grid className={classes.title}>
          {errorCode} - {errorTitle}
        </Grid>
        <Grid className={classes.message}>{errorMessage}</Grid>
        <Grid>{!!children && children}</Grid>
      </Container>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 55,
  },
  message: {
    fontSize: 45,
    color: colors.gray,
  },
}));

export default Error;
