import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, createStyles, makeStyles } from '@material-ui/core';
import { IFormItem } from '../interface';
import Input from './Input';

export interface SelectBoxProps {
  label: string;
  options: string[];
  disabled?: boolean;
  data: IFormItem<string>;
}

const SelectBox: React.FC<SelectBoxProps> = (props: SelectBoxProps) => {
  const { data, options, ...others } = props;
  const { t } = useTranslation();
  const classes = styles();

  return (
    <Input select data={data} label={t('Phone number')} className={classes.inputSmall} {...others}>
      {options.map((option: string, index: number) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </Input>
  );
};

const styles = makeStyles(() =>
  createStyles({
    inputSmall: {
      width: 150,
      marginRight: 10,
    },
  }),
);

export default SelectBox;
