import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { FormInput } from '../type';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    margin: theme.spacing(),
  },
}));

function SwitchInput({ value, setValue, label, editable }: FormInput<boolean>) {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
  };
  return (
    <FormControlLabel
      className={classes.input}
      control={<Switch onChange={handleChange} checked={value} color="primary" />}
      label={label}
      disabled={!editable}
    />
  );
}

export default observer(SwitchInput);
