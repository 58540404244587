import { observable, action, computed, toJS, runInAction } from 'mobx';
import { ShopRelationType } from '@mtt-nails/consts';
import { BaseStore } from '../../../../cores';
import { supportApi } from '@mtt-nails/apis/dist/v3';
import i18n from '../../../../i18n';

export interface Info {
  email: string;
  relation: string;
  shopId: string;
  staffCode: string;
  nickName: string;
}

export default class extends BaseStore {
  @observable info: Info = {
    email: '',
    relation: '',
    shopId: '',
    staffCode: '',
    nickName: '',
  };
  @observable addSuccess = false;
  @observable loading = false;

  @computed get email() {
    return this.info.email;
  }
  @computed get relation() {
    return this.info.relation;
  }
  @computed get shopId() {
    return this.info.shopId;
  }
  @computed get staffCode() {
    return this.info.staffCode;
  }
  @computed get nickName() {
    return this.info.nickName;
  }
  @computed get shopRelations() {
    return Object.keys(ShopRelationType).map(r => ({
      label: i18n.t(r),
      value: ShopRelationType[r as keyof typeof ShopRelationType],
    }));
  }
  @computed get disabled() {
    const required = ['email', 'relation', 'shopId'];
    const disabled = Object.keys(toJS(this.info)).every(key =>
      required.includes(key) ? !!this.info[key as keyof Info] : true,
    );
    return !disabled;
  }

  @action setInfo = (value: Info) => {
    this.info = value;
  };
  @action resetInfo = () => {
    this.info = {
      email: '',
      relation: '',
      shopId: '',
      staffCode: '',
      nickName: '',
    };
  };

  @action
  handleSave = async () => {
    const info = toJS(this.info);
    Object.keys(info).forEach(key => {
      if (!info[key as keyof Info]) {
        delete info[key as keyof Info];
      }
    });
    try {
      this.loading = false;
      await supportApi.createShopUser(info);
      this.resetInfo();
      runInAction(() => {
        this.addSuccess = true;
      });
      this.setSuccess(i18n.t('Success'));
    } catch (error) {
      this.setError(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
