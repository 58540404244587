import * as React from 'react';
import { observable, IObservableArray } from 'mobx';
import { observer } from 'mobx-react';

const uRoles: IObservableArray<string> & string[] = observable([]);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function roles(target: Record<string, any>, key: string | symbol, baseDescriptor?: PropertyDescriptor): any {
  return {
    enumerable: true,
    configurable: true,
    set: function(roles: string[] = []): void {
      uRoles.replace(roles);
    },
    get: function(): string[] {
      return uRoles;
    },
  };
}

const canAccess = (availableRoles: string[]) => {
  if (availableRoles.length === 0) return true;
  for (let index = 0; index < uRoles.length; index++) {
    if (availableRoles.includes(uRoles[index])) return true;
  }
  return false;
};

export default function withRoles(roles: string[]) {
  return (Component: any): any => {
    return observer((props: any) => {
      if (!canAccess(roles)) return null;
      return <Component {...props} />;
    });
  };
}
