import { Grid } from '@material-ui/core';
import { Support } from '@mtt-nails/apis/dist/v3';
import { SalaryType } from '@mtt-nails/consts';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DateInput, DefaultInput, SelectInput } from '../Items';
import { Form } from '../type';

const SalaryForm: React.FC<Form<Support.StaffSalary>> = ({ editable, store }) => {
  const { t } = useTranslation();

  const handleChange = (key: string) => (value: any) => {
    store.setValue({
      ...store.value,
      [key]: value,
    });
  };

  return (
    <Grid container>
      <Grid item>
        <SelectInput
          label={t('Salary Type')}
          value={store.value.salaryType}
          setValue={handleChange('salaryType')}
          editable={editable}
          options={Object.values(SalaryType).map(key => ({ label: t(`Salary.${key}`), value: key }))}
        />
      </Grid>
      <Grid item>
        <DateInput
          value={store.value.startDate}
          label={t('Start date')}
          setValue={handleChange('startDate')}
          editable={editable}
        />
      </Grid>
      <Grid item>
        <DateInput
          value={store.value.endDate}
          label={t('End date')}
          setValue={handleChange('endDate')}
          editable={editable}
          minDate={store.value.startDate}
        />
      </Grid>
      {store.value.salaryType !== SalaryType.Fix && (
        <Grid item>
          <DefaultInput
            value={store.value.amountPercent}
            label={t('Amount percent')}
            setValue={handleChange('amountPercent')}
            editable={editable}
            type="number"
          />
        </Grid>
      )}
      {store.value.salaryType !== SalaryType.Percent && (
        <Grid item>
          <DefaultInput
            value={store.value.amountFix}
            label={t('Amount fix')}
            setValue={handleChange('amountFix')}
            editable={editable}
            type="number"
          />
        </Grid>
      )}
      {store.value.salaryType !== SalaryType.Percent && (
        <Grid item>
          <DefaultInput
            value={store.value.workDays}
            label={t('Work day')}
            setValue={handleChange('workDays')}
            editable={editable}
            type="number"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default observer(SalaryForm);
