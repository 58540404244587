import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  Select,
  InputLabel,
} from '@material-ui/core';
import { observable, runInAction, action } from 'mobx';
import { useTranslation } from 'react-i18next';
import { ShopRelationType } from '@mtt-nails/consts';
import { Support, supportApi } from '@mtt-nails/apis/dist/v3';

export class AddRelationStore {
  @observable
  isOpen = false;

  @observable
  title: string;

  @observable
  role: string;

  @observable
  shop: Support.ShopBase = Support.ShopBase.from({});

  @observable
  shops: Support.ShopBase[] = [];

  @action
  fetch = async () => {
    try {
      const { data } = await supportApi.getShops();
      runInAction(() => {
        this.shops = Support.ShopBase.fromArray(data);
      });
    } catch (error) {
      console.log(error);
    }
  };
}

interface Props {
  userId: string;
  store: AddRelationStore;
  onSubmit(shopId: string, userId: string, relationName: string): void;
  onClose(): void;
}

const AddRelation: React.FC<Props> = (props: Props) => {
  const { store, onClose, onSubmit } = props;
  const { t } = useTranslation();
  const classes = styles();

  useEffect(() => {
    store.fetch();
  }, [store]);

  const roleSelect = (value: string) => {
    runInAction(() => {
      store.role = value;
    });
  };

  const shopSelect = (value: string) => {
    runInAction(() => {
      store.shop.id = value;
    });
  };

  return (
    <>
      <Dialog open={store.isOpen} onClose={onClose}>
        <DialogTitle>{t(store.title)}</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <InputLabel>{t('Shops')}</InputLabel>
            <Select native onChange={event => shopSelect(event.target.value as string)}>
              <option value="" />
              {store.shops.map(shop => (
                <option value={shop.id} key={shop.id}>
                  {shop.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>{t('Roles')}</InputLabel>
            <Select native onChange={event => roleSelect(event.target.value as string)}>
              <option value="" />
              {Object.keys(ShopRelationType).map(e => (
                <option value={e} key={e}>
                  {t(e)}
                </option>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onSubmit(store.shop.id, props.userId, store.role)} color="primary">
            {t('CONFIRM')}
          </Button>
          <Button onClick={onClose} color="primary" autoFocus>
            {t('CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

export default observer(AddRelation);
