import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, Observer } from 'mobx-react';
import { observable, computed, action } from 'mobx';
import { Grid } from '@material-ui/core';
import { Item, Form } from '../helper';
import { IFormItem } from '../interface';
import Input from '../Items/Input';
import SelectCountryBox from '../Items/SelectCountryBox';

interface AddressType {
  country: string;
  zip: string;
  street: string;
  city: string;
  state: string;
}

const initial: AddressType = {
  country: '',
  zip: '',
  street: '',
  city: '',
  state: '',
};

export class Address implements IFormItem<AddressType> {
  @observable
  form: Form = new Form();

  constructor(address: AddressType = initial) {
    this.form.items['country'] = new Item({ value: address.country });
    this.form.items['zip'] = new Item({ value: address.zip });
    this.form.items['street'] = new Item({ value: address.street });
    this.form.items['city'] = new Item({ value: address.city });
    this.form.items['state'] = new Item({ value: address.state });
  }

  @computed
  get value() {
    return {
      country: this.form.items['country'].value,
      zip: this.form.items['zip'].value,
      street: this.form.items['street'].value,
      city: this.form.items['city'].value,
      state: this.form.items['state'].value,
    };
  }

  setValue = () => {};

  isDirty = () => this.form.isDirty();

  isValid = () => this.form.isValid();

  @action
  onReset = () => {
    this.form.onReset();
  };

  @action
  onSetDefault = () => {
    this.form.onSetDefault();
  };
}

interface Props {
  data: Address;
  disabled?: boolean;
}

const AddressControl: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} spacing={4}>
      <Observer>{() => <SelectCountryBox data={data.form.items.country} />}</Observer>
      <Observer>{() => <Input label={t('Zipcode')} data={data.form.items.zip} />}</Observer>
      <Observer>{() => <Input label={t('Street')} data={data.form.items.street} />}</Observer>
      <Observer>{() => <Input label={t('City')} data={data.form.items.city} />}</Observer>
      <Observer>{() => <Input label={t('State')} data={data.form.items.state} />}</Observer>
    </Grid>
  );
};

export default observer(AddressControl);
