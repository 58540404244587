import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

export class ModalStore {
  @observable
  isModalOpen = false;

  @observable
  modalTitle: string;

  @observable
  modalMessage: string;
}

interface Props {
  store: ModalStore;
  onClose(): void;
  onSubmit(): void;
}

const Modal: React.FC<Props> = (props: Props) => {
  const { store, onClose, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={store.isModalOpen} onClose={onClose}>
      <DialogTitle>{t(store.modalTitle)}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(store.modalMessage)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="primary">
          {t('CONFIRM')}
        </Button>
        <Button onClick={onClose} color="primary" autoFocus>
          {t('CANCEL')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(Modal);
