import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import { FormInput } from '../type';

const CheckboxInput: React.FC<FormInput<boolean>> = ({ value, setValue, editable, label }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
  };

  return (
    <FormControlLabel
      disabled={!editable}
      control={<Checkbox onChange={handleChange} checked={value} color="primary" />}
      label={label}
    />
  );
};

export default CheckboxInput;
