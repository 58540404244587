import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';

import { StoreNames, RootStore } from '../../../../stores';
import Store from './store';
import { DefaultInput, SelectInput } from '../../../../components/FormControl/Items';
import { Alert } from '../../../../components';

interface Props extends Partial<RootStore> {
  open: boolean;
  onClose: () => void;
}

const store = new Store();

const AddUserModal: React.FC<Props> = ({ open, onClose, Shops }) => {
  const { t } = useTranslation();

  reaction(
    () => store.addSuccess,
    isSuccess => {
      isSuccess && onClose();
    },
  );

  const shops = useMemo<{ label: string; value: string }[]>(() => {
    const _shops = Shops ? Shops.shops : [];
    return _shops.map(s => ({ label: s.name, value: s.id }));
  }, [Shops]);

  const handleChange = useCallback((key: string) => {
    return (value: string) =>
      store.setInfo({
        ...store.info,
        [key]: value,
      });
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    store.resetInfo();
  }, [onClose]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('Staff Information')}</DialogTitle>
        <DialogContent>
          <DefaultInput label={t('Email')} value={store.email} setValue={handleChange('email')} editable={true} />
          <SelectInput
            value={store.shopId}
            options={shops}
            setValue={handleChange('shopId')}
            label={t('Shop')}
            editable={true}
          />
          <SelectInput
            value={store.relation}
            options={store.shopRelations}
            setValue={handleChange('relation')}
            label={t('Relation')}
            editable={true}
          />
          <DefaultInput
            label={t('Staff Code')}
            value={store.staffCode}
            setValue={handleChange('staffCode')}
            editable={true}
          />
          <DefaultInput
            label={t('Nickname')}
            value={store.nickName}
            setValue={handleChange('nickName')}
            editable={true}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" disabled={store.disabled} onClick={store.handleSave}>
            {t('SAVE')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Alert store={store.alertStore} onClose={store.hideAlert} />
    </>
  );
};

export default inject(StoreNames.Shops)(observer(AddUserModal));
