import { Grid } from '@material-ui/core';
import { Support } from '@mtt-nails/apis/dist/v3';
import ct from 'countries-and-timezones';
import { CountryCode } from 'libphonenumber-js';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectDate, SelectTime, SpecialDay } from '../Items';
import { Form } from '../type';

interface Props extends Form<Support.OpeningTimes> {
  cca2: CountryCode;
}

function TimeForm({ editable, store, cca2 }: Props) {
  const { t } = useTranslation();
  React.useEffect(() => {
    const [timezone] = ct.getTimezonesForCountry(cca2);
    store.setValue({
      ...store.value,
      timezone: timezone.utcOffsetStr,
    });
  }, [cca2, store]);

  const setValue = (key: string) => (value: any) => {
    store.setValue({
      ...store.value,
      [key]: value,
    });
  };
  const setOffDates = (value: Date[]) => {
    const [date] = value;
    const offDates = [...store.value.offDates];
    const idx = offDates.findIndex(d => moment(d).isSame(moment(date)));

    if (idx >= 0) offDates.splice(idx, 1);
    else offDates.push(date.toString());
    store.setValue({ ...store.value, offDates: offDates });
  };
  const setTime = (key: string) => (value: Date) => {
    store.setValue({
      ...store.value,
      [key]: moment(value).format('HH:mm'),
    });
  };

  return (
    <Grid container alignItems="center">
      <Grid item>
        <SelectTime
          value={moment(store.value.open, 'HH:mm').toDate()}
          setValue={setTime('open')}
          editable={editable}
          label={t('Opened time')}
        />
      </Grid>
      <Grid item>
        <SelectTime
          value={moment(store.value.close, 'HH:mm').toDate()}
          setValue={setTime('close')}
          editable={editable}
          label={t('Closed time')}
        />
      </Grid>
      <Grid item>
        <SelectDate
          value={store.value.offDates.map(d => new Date(d))}
          setValue={setOffDates}
          editable={editable}
          label={t('Day off')}
        />
      </Grid>
      <Grid item>
        <SpecialDay
          value={store.value.specialWorkDay}
          setValue={setValue('specialWorkDay')}
          editable={editable}
          label={t('Special Work Day')}
        />
      </Grid>
    </Grid>
  );
}

export default observer(TimeForm);
